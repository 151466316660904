import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./table.css";
import logo from "./logo.png";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { useDownloadExcel } from "react-export-table-to-excel";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center",
    home: {
        textAlign: "center",
        marginLeft: "80px",
        marginTop: "20px"
    },
    linha: {
        paddingTop: "20px"
    }
});

function tableBaixaTensao({ classes }) {
    const [date, setDate] = useState();

    const [setinfos, setInfosTabela] = useState([]);

    const testereload = () => {
        console.log("oi");
    };

    useEffect(() => {
        axios
            .get(
                "http://161.97.71.206:443/api/baixatensao?dt1=" +
                    date +
                    "&dt2=" +
                    date
                // ?dt1=2022-11-09&dt2=2022-11-09"
            )
            .then(response => {
                setInfosTabela(response.data);
                console.log(response.data);
            });
    }, [date]);
    setInterval(date, 5000);
    const data = [
        {
            Empresa: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.SIGLA}</p>;
            }),
            COF: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.COF}</p>;
            }),
            CO: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.CO}</p>;
            }),
            "Ch Ura": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Ch Ura"]}</p>;
            }),
            "Ca < 30s": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Ca < 30s"]}</p>;
            }),
            "Ca > 30s": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Ca > 30s"]}</p>;
            }),
            CA: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.Ca}</p>;
            }),
            "Cab < 30s": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Cab < 30s"]}</p>;
            }),
            "Cab > 30s": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Cab > 30s"]}</p>;
            }),
            Cab: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.Cab}</p>;
            }),
            "CR Rec": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["CR Rec"]}</p>;
            }),
            "CR Plan": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["CR Plan"]}</p>;
            }),
            "INS 85-30": setinfos.map(repo => {
                return (
                    <p className="LinhaTst borderIns">
                        {repo["INS 85-30"] + "%"}
                    </p>
                );
            }),
            "IAB %": setinfos.map(repo => {
                return (
                    <p className="LinhaTst borderIab">{repo["IAB %"] + "%"}</p>
                );
            }),
            "CR (Pla x Rec)": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["CR (Pla x Rec)"]}</p>;
            }),
            "Cat/Rec %": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Cat/Rec %"]}</p>;
            }),
            TMA: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.TMA}</p>;
            }),
            TMAb: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.TMAb}</p>;
            }),
            TME: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.TME}</p>;
            }),
            "Agt.": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Agt."]}</p>;
            }),
            Limite: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.Limite}</p>;
            })
        }
    ];

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "DadosBaixaTensão" + date,
        sheet: "DadosBaixaTensão" + date
    });

    const Head = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const Row = ({ record }) => {
        return (
            <tr className="testeTABE" key={record.id}>
                {keys.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    const keys = Object.keys(data[0]);

    const ChangeDate = () => {};

    return (
        <body>
            <div className="dados-gerais">
                <div className="header-flexstart">Dados Gerais</div>

                <div className="date-right">
                    <form>
                        <input
                            value={date}
                            onChange={e => setDate(e.target.value)}
                            type="date"
                            className="input-date datePicker"
                        ></input>
                        {/* <button type="submit"
                        value={date}
                        onSubmit={e => setDate(e.target.value)}
                            className="button-consultar"
                        >
                            CONSULTAR
                        </button> */}
                    </form>
                </div>
            </div>
            {/* <p className="selectedDateNow" >
                        Data<br/>
                        <br/>
                        {date}
                    </p> */}
            <table ref={tableRef} className={classes.home}>
                <Head keys={keys} />
                <tbody className="LinhaTst">
                    {data.map(record => (
                        <Row record={record} />
                    ))}
                </tbody>
            </table>
            <button className="button-excelexport" onClick={onDownload}>
                Baixar dados da tabela em Excel
            </button>
        </body>
    );
}

tableBaixaTensao.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(tableBaixaTensao);
