// Libs
import { connect } from "react-redux";
// Component
import RedefinirSenha from "../../components/Sidebar/RedefinirSenhaItem";
// Constants
import { ADM_RESET_PASS } from "../../constants/routes";
// Selectors
import { isItemSelected } from "../../redux/selectors/ui";
import history from "../../history";
import { withRouter } from "react-router";

const mapStateToProps = state => ({
    selected: isItemSelected(state)
});

const mapDispatchToProps = {
    onClick: () => history.push(ADM_RESET_PASS)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RedefinirSenha));
