import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import "./Sidebar.css";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import TvIcon from "@material-ui/icons/Tv";
import DescriptionIcon from "@material-ui/icons/Description";
import SidebarHomeItem from "../../containers/Sidebar/HomeItem";
import SidebarBaixaTensaoItem from "../../containers/Sidebar/BaixaTensaoItem";
import SidebarRelatorioAneelItem from "../../containers/Sidebar/RelatorioAneelItem";
import SidebarLoginItem from "../../containers/Sidebar/LoginItem";
import SidebarGranClientesItem from "../../containers/Sidebar/GrandesClientesItem";
import SidebarHeader from "../../containers/Sidebar/Header";
import SidebarOuvidoriaItem from "../../containers/Sidebar/PainelOuvidoriaItem";
import SidebarProconsItem from "../../containers/Sidebar/PainelProconItem";
import SidebarRelatorioAneelMensalItem from "../../containers/Sidebar/RelatorioAneelMensalItem";
import SidebarRelatorioAneelAgregadoItem from "../../containers/Sidebar/RelatorioAneelAgregadoItem";
import SidebarRelatorioAneelAnualItem from "../../containers/Sidebar/RelatorioAneelAnualItem";
import SidebarRelatorioAneelAnualAgregItem from "../../containers/Sidebar/RelatorioAneelAnualAgregItem";
import SidebarRelatorioAneelQualidadeItem from "../../containers/Sidebar/RelatorioAneelQualidadeItem";
import SidebarRelatorioAneelAtendimentoItem from "../../containers/Sidebar/RelatorioAneelAtendimentoItem";
import AlterarTipoUsuarioItem from "../../containers/Sidebar/AlterarTipoUsuarioItem";

import SidebarRedefinirSenhaItem from "../../containers/Sidebar/RedefinirSenhaItem";
import SidebarBscItem from "../../containers/Sidebar/BSCChamadasItem";
import SidebarCCVMensalItem from "../../containers/Sidebar/CCVMensalItem";
import SidebarBscIabItem from "../../containers/Sidebar/BscIndIabItem";
import SidebarBscIcotem from "../../containers/Sidebar/BscIndIcoItem";
import SidebarBscInsItem from "../../containers/Sidebar/BscIndInsItem";
import SidebarBscTmaItem from "../../containers/Sidebar/BscTemTma";
import SidebarCCVAnualItem from "../../containers/Sidebar/CCVAnualItem";
import SidebarCustoTelefoniaItem from "../../containers/Sidebar/CustoTelefItem";
import SidebarCustoDemaisItem from "../../containers/Sidebar/CustoDemItem";
import SidebarCustoConsolidadoItem from "../../containers/Sidebar/CustoConsolidadoItem";
import SidebarBscUraItem from "../../containers/Sidebar/BSCUraRelItem";
import SidebarBscAtendidasItem from "../../containers/Sidebar/BSCAtendItem";
import SidebarBscMinutagemItem from "../../containers/Sidebar/BSCMinutItem";
import SidebarBscRecebidasItem from "../../containers/Sidebar/BSCRecebItem";
import SidebarBSCRETURAITEM from "../../containers/Sidebar/BSCRETURAITEMREL";
import SidebarBSCRETNUMITEM from "../../containers/Sidebar/BSCRETNUMITEMREL";
import SidebarOrcamentoMensalItem from "../../containers/Sidebar/OrcamentoMenItem";
import SidebarOrcamentoAnualItem from "../../containers/Sidebar/OrcamentoAnuItem";
import SidebarRelAneelDetalheItem from "../../containers/Sidebar/RelatorioDetalheDiarioItem";
import history from "../../history";

const styles = theme => ({
    drawer: {
        height: "100vh",
        position: "fixed",
        zIndex: "2"
    },
    drawerPaper: {
        position: "relative",
        height: "100%",
        width: 240,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        width: 0,
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up("sm")]: {
            width: 185
        }
    },
    drawerInner: {
        // Make the items inside not wrap when transitioning:
        width: 240
    }
});

const Sidebar = ({
    classes,
    width,
    variant,
    open,
    onClose,
    hideRoutes,
    userb,
    loggedUser
}) => {
    const isLargeScreen = isWidthUp("sm", width);
    const sendLogout = us => {
        history.push("/");
        userb("");
    };

    let menuAdm = "";
    if (loggedUser !== undefined) {
        if (loggedUser.TIPO === 0) {
            menuAdm = (
                <>
                    <hr className="hr-sidebar"></hr>
                    <div className="cabecalho-side">Acesso</div>
                    <div>
                        <SidebarRedefinirSenhaItem />
                        <AlterarTipoUsuarioItem />
                        <SidebarLoginItem clickProp={sendLogout} />
                    </div>
                    <hr className="hr-sidebar"></hr>
                </>
            );
        }
    }

    return (
        <div>
            {hideRoutes === true ? (
                <div></div>
            ) : (
                <Drawer
                    variant={isLargeScreen ? "permanent" : undefined}
                    classes={
                        isLargeScreen
                            ? {
                                  docked: classes.drawer,
                                  paper: classNames(
                                      classes.drawerPaper,
                                      !open && classes.drawerPaperClose
                                  )
                              }
                            : {}
                    }
                    open={open}
                    onClose={onClose}
                >
                    <div className={classes.drawerInner}>
                        <div>
                            <SidebarHeader />
                            <List>
                                {/* <SidebarHomeItem /> */}
                                {/* <hr className="hr-sidebar"></hr> */}
                                {/* <p className="painel-sidebar">Painel</p> */}
                                <div className="cabecalho-side">
                                    Monitoramento
                                </div>
                                <div className="painel-format">
                                    <TvIcon fontSize="small" />{" "}
                                    <div className="painel-icon">Painel</div>
                                </div>
                                <SidebarBaixaTensaoItem />
                                <SidebarGranClientesItem />
                                <SidebarOuvidoriaItem />
                                <SidebarProconsItem />

                                <hr className="hr-sidebar"></hr>
                                {/* <p className="relatorio-sidebar">Relatório</p> */}
                                <div className="cabecalho-side">Relatórios</div>
                                <div className="painel-format">
                                    <DescriptionIcon fontSize="small" />{" "}
                                    <div className="painel-icon">Aneel</div>
                                </div>
                                <div>
                                    <SidebarRelatorioAneelAnualItem />
                                    <SidebarRelatorioAneelAnualAgregItem />
                                    <SidebarRelatorioAneelAtendimentoItem />
                                    <SidebarRelAneelDetalheItem />
                                    <SidebarRelatorioAneelMensalItem />
                                    <SidebarRelatorioAneelAgregadoItem />

                                    <SidebarRelatorioAneelQualidadeItem />
                                </div>
                                {/* <div className="painel-formatbsc">
                                    <DescriptionIcon fontSize="small" />{" "}
                                    <div className="painel-icon">Orcamento</div>
                                </div>
                                <SidebarOrcamentoAnualItem />
                                <SidebarOrcamentoMensalItem />
                                <div className="painel-formatbsc">
                                    <DescriptionIcon fontSize="small" />{" "}
                                    <div className="painel-icon">CCV</div>
                                </div>
                                <SidebarCCVAnualItem />
                                <SidebarCCVMensalItem />

                                <div className="painel-formatbsc">
                                    <DescriptionIcon fontSize="small" />{" "}
                                    <div className="painel-icon">BSC</div>
                                </div>
                                <div className="painel-iconcustos">
                                    Custos Telefonia
                                </div>
                                <SidebarCustoTelefoniaItem />
                                <SidebarCustoConsolidadoItem />
                                <SidebarCustoDemaisItem />
                                <div className="painel-iconbsc">Chamadas</div>
                                <SidebarBscItem />
                                <SidebarBscUraItem />
                                <SidebarBscAtendidasItem />
                                <SidebarBscMinutagemItem />
                                <SidebarBscRecebidasItem />
                                <SidebarBSCRETURAITEM />
                                <SidebarBSCRETNUMITEM />
                                <div className="painel-iconindic">
                                    Indicador
                                </div>
                                <SidebarBscIabItem />
                                <SidebarBscIcotem />
                                <SidebarBscInsItem />
                                <div className="painel-icontemp">Tempos</div>
                                <SidebarBscTmaItem /> */}
                                {menuAdm}
                                {/* <SidebarLoginItem clickProp={sendLogout} /> */}
                            </List>
                        </div>
                    </div>
                </Drawer>
            )}
        </div>
    );
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    variant: PropTypes.string,
    toogleb: PropTypes.any,
    open: PropTypes.bool,
    hideRoutes: PropTypes.any,
    onClose: PropTypes.func,
    userb: PropTypes.any
};

export default compose(withWidth(), withStyles(styles))(Sidebar);
