import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Select from "react-select";
import RemoveIcon from "@material-ui/icons/Remove";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useDownloadExcel } from "react-export-table-to-excel";
import crypto from "crypto";

import { useState } from "react";
import {
    XAxis,
    Tooltip,
    CartesianGrid,
    Line,
    YAxis,
    Bar,
    ComposedChart,
    LabelList
} from "recharts";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center",
    home: {
        textAlign: "center",
        marginLeft: "80px",
        marginTop: "20px"
    },
    Sum: {
        textAlign: "center",
        marginLeft: "80px"
    },
    linha: {
        paddingTop: "20px"
    }
});

function Aneel({ classes }) {
    const [setInfosAnual, setInfosAnualTabela] = useState([]);
    const [setInfosAnualGrf, setInfosAnualGrafico] = useState([]);
    const [setInfosSumarizada, setInfosTabelaSumarizada] = useState([]);
    const [empresa, setEmpresa] = useState();
    const [ano, setAno] = useState();
    const [tipicidade, setTipicidade] = useState();
    const [empresaLabel, setEmpresaLabel] = useState();
    const [anoLabel, setAnoLabel] = useState();
    const [tipicidadeLabel, setTipicidadeLabel] = useState();
    const [mesesFechados, setMesesFechados] = useState();
    const [exibirDados, setExibirDados] = useState(false);

    const axios = require("axios");

    async function getBearerToken() {
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

        const timestamp = Math.floor(Date.now() / 1000);
        const nonce = crypto.randomBytes(16).toString("hex");

        const body = new URLSearchParams();
        body.append("grant_type", "client_credentials");
        body.append("scope", "gestoronline");

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${Buffer.from(
                    `${clientId}:${clientSecret}`
                ).toString("base64")}`,
                "X-Timestamp": timestamp,
                "X-Nonce": nonce
            }
        };

        try {
            const response = await axios.post(
                "https://161.97.71.206/connect/token",
                body.toString(),
                config
            );
            return response.data.access_token;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async function SearchData() {
        const token = await getBearerToken();
        setExibirDados(true);
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioAtendRecebPlan?id_empresa=" +
                    empresa +
                    "&ano=" +
                    ano +
                    "&mes=" +
                    mesesFechados +
                    "&tp=" +
                    tipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosAnualTabela(response.data);
                console.log(response.data);
            });
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioAtendRecebPlanGrafico?id_empresa=" +
                    empresa +
                    "&ano=" +
                    ano +
                    "&mes=" +
                    mesesFechados +
                    "&tp=" +
                    tipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosAnualGrafico(response.data);
                console.log(response.data);
            });
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalTotalizador?id_empresa=" +
                    empresa +
                    "&ano=" +
                    ano +
                    "&mes=" +
                    mesesFechados +
                    "&tp=" +
                    tipicidade +
                    "&rs=2&tpse=1",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosTabelaSumarizada(response.data);
                console.log(response.data);
            });
    }

    const data = [
        {
            Dia: setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.dia}</p>;
            }),
            COF: setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cof
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CO: setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.co}</p>;
            }),
            "Ch Ura": setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cura
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca < 30s": setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat_ate_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca > 30s": setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat_maior_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CA: setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Cab < 30s": setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.cab_ate_30s}</p>;
            }),
            "Cab > 30s": setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cab_maior_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            Cab: setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.cab}</p>;
            }),
            "CR Rec": setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cre
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "CR Plan": setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.chpl
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "INS 30s": setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.ins_30s + "%"}</p>;
            }),
            "INS 60s": setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.ins_60s + "%"}</p>;
            }),
            "IAB %": setInfosAnual.map(repo => {
                if (repo.iab_maior_30s > 4) {
                    return (
                        <p className="LinhaTst borderIabGreen">
                            {repo.iab_maior_30s + "%"}
                        </p>
                    );
                } else {
                    return (
                        <p className="LinhaTst borderIabRed">
                            {repo.iab_maior_30s + "%"}
                        </p>
                    );
                }
            }),
            "ICO %": setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.ico + "%"}</p>;
            }),
            "CR Prev % ": setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.cre_plan + "%"}</p>;
            }),
            "Cat/Rec % ": setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.cat_rec + "%"}</p>;
            }),
            TMA: setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.tma}</p>;
            }),
            TMAb: setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.tmab}</p>;
            }),
            TME: setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.tme}</p>;
            }),
            "Agt.": setInfosAnual.map(repo => {
                return <p className="LinhaTst">{repo.agentes}</p>;
            }),
            Limite: setInfosAnual.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.limite
                            .toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            })
        }
    ];

    const dataTableSum = [
        {
            Tipo: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.tipo}</p>;
            }),
            COF: setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cof
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CO: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.co}</p>;
            }),
            "Ch Ura": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cura
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca < 30s": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat_ate_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca > 30s": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat_maior_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CA: setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Cab < 30s": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.cab_ate_30s}</p>;
            }),
            "Cab > 30s": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cab_maior_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            Cab: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.cab}</p>;
            }),
            "CR Rec": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cre
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "CR Plan": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.chpl
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "INS 30s": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.ins + "%"}</p>;
            }),
            "INS 60s": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.ins_60s + "%"}</p>;
            }),
            "IAB %": setInfosSumarizada.map(repo => {
                if (repo.iab > 4) {
                    return (
                        <p className="LinhaTst borderIabGreen">
                            {repo.iab + "%"}
                        </p>
                    );
                } else {
                    return (
                        <p className="LinhaTst borderIabRed">
                            {repo.iab + "%"}
                        </p>
                    );
                }
            }),
            "ICO %": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.ico + "%"}</p>;
            }),
            "CR Prev % ": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.cre_plan + "%"}</p>;
            }),
            "Cat/Rec % ": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.cat_rec + "%"}</p>;
            }),
            TMA: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.tma}</p>;
            }),
            TMAb: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.tmab}</p>;
            }),
            TME: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.tme}</p>;
            }),
            "Agt.": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.agentes}</p>;
            }),
            Limite: setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.limite
                            .toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            })
        }
    ];

    const Head = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };

    const Row = ({ record }) => {
        return (
            <tr className="testeTABE" key={record.id}>
                {keys.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    const HeadSum = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const RowSum = ({ record }) => {
        return (
            <tr className="testeTABE" key={record.id}>
                {keysSum.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    var infodata = setInfosAnualGrf.map(repo => {
        return repo;
    });

    const keys = Object.keys(data[0]);
    const keysSum = Object.keys(dataTableSum[0]);

    const options2 = [
        { value: "1", label: "Energisa Mato Grosso" },
        { value: "2", label: "Energisa Tocantins" },
        { value: "1002", label: "Energisa Mato Grosso do Sul" },
        { value: "1008", label: "Energisa Paraíba" },
        { value: "1009", label: "Energisa Borborema" },
        { value: "1010", label: "Energisa Sergipe" },
        { value: "1011", label: "Energisa Minas Gerais" },
        { value: "1012", label: "Energisa Nova Friburgo" },
        { value: "1013", label: "Energisa Sul Sudeste" },
        { value: "1014", label: "Energisa Acre" },
        { value: "1015", label: "Energisa Rondônia" }
    ];
    const options3 = [
        { value: "2021", label: "2021" },
        { value: "2022", label: "2022" },
        { value: "2023", label: "2023" }
    ];

    const options4 = [
        { value: "0", label: "Geral" },
        { value: "1", label: "Típicos" }
    ];

    const options5 = [
        { value: "1", label: "Janeiro" },
        { value: "2", label: "Fevereiro" },
        { value: "3", label: "Março" },
        { value: "4", label: "Abril" },
        { value: "5", label: "Maio" },
        { value: "6", label: "Junho" },
        { value: "7", label: "Julho" },
        { value: "8", label: "Agosto" },
        { value: "9", label: "Setembro" },
        { value: "10", label: "Outubro" },
        { value: "11", label: "Novembro" },
        { value: "12", label: "Dezembro" }
    ];

    const handleEmpresa = selected => {
        setEmpresa(selected.value);
        setEmpresaLabel(selected.label);
    };
    const handleAno = selected => {
        setAno(selected.value);
        setAnoLabel(selected.label);
    };
    const handleTipicidade = selected => {
        setTipicidade(selected.value);
        setTipicidadeLabel(selected.label);
    };
    const handleMesesFechados = selected => {
        setMesesFechados(selected.value);
    };
    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "DadosAtendPlan" + ano,
        sheet: "DadosAtendPlan" + ano
    });

    return (
        <body>
            <div className="responsive-geral">
                <div className="header-select">
                    <div className="header-aneel">
                        <h3>Aneel</h3>
                        <p>Atendimento x Receb x Planej</p>
                    </div>
                    {/* <div className="end-header">
                        <Select
                            placeholder="Baixa Tensão"
                            options={options}
                            isClearable={true}
                        />
                    </div> */}
                </div>
                {/* <hr></hr> */}

                <div className="pesquisa-aneelanual">
                    <div className="input-flexcolumn">
                        Empresa
                        <Select
                            placeholder="Empresa"
                            options={options2}
                            onChange={handleEmpresa}
                        />
                    </div>
                    <div className="input-flexcolumn">
                        Ano
                        <Select
                            placeholder="Ano"
                            options={options3}
                            onChange={handleAno}
                        />
                    </div>
                    <div className="input-flexcolumn">
                        Mês
                        <Select
                            placeholder="Mes"
                            options={options5}
                            onChange={handleMesesFechados}
                        />
                    </div>
                    <div className="input-flexcolumn">
                        Tipicidade
                        <Select
                            placeholder="Tipicidade"
                            options={options4}
                            onChange={handleTipicidade}
                        />{" "}
                    </div>

                    <button
                        // value={date}
                        onClick={SearchData}
                        className="button-aneel"
                    >
                        CONSULTAR
                    </button>
                </div>
                <ComposedChart
                    width={1230}
                    height={255}
                    data={infodata}
                    margin={{ bottom: 20, left: 38 }}
                >
                    <XAxis dataKey="dia" />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Bar
                        dataKey="limite"
                        barSize={20}
                        fill="#00cce2"
                        isAnimationActive={false}
                    >
                        <LabelList
                            dataKey="limite"
                            fontSize={11.5}
                            position="top"
                            angle={-45}
                            offset={12}
                        />
                    </Bar>
                    <Line
                        activeDot={true}
                        strokeWidth={2}
                        type="monotone"
                        dataKey="cre"
                        stroke="red"
                    />
                    <Line
                        activeDot={true}
                        strokeWidth={2}
                        type="monotone"
                        dataKey="cat"
                        stroke="blue"
                    />
                    <Line
                        activeDot={true}
                        strokeWidth={2}
                        type="monotone"
                        dataKey="chpl"
                        stroke="green"
                    />
                </ComposedChart>
                <div className="sinopse-mainAnual">
                    <div className="header-flexicontst">
                        <div className="header-flex">
                            <RemoveIcon className="IconLine" />{" "}
                            <p className="DownLine">Limite ANEEL</p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine2" />
                            <p className="DownLine">Recebidas</p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine3" />
                            <p className="DownLine">Planejadas</p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine4" />
                            <p className="DownLine">Atendidas</p>
                        </div>
                    </div>

                    {/* <div>🟡 Dia Típico</div>
                    <div>🟡 Dia Típico - INS Abaixo de 85%</div>
                    <div>🔵 Dia Atípico</div> */}
                </div>
            </div>
            {exibirDados && (
                <div className="dados-tipicosmensal">
                    {empresaLabel} / {anoLabel} ({tipicidadeLabel})
                </div>
            )}
            <div className="table-mensal">
                <table ref={tableRef} className={classes.home}>
                    <Head keys={keys} />
                    <tbody className="LinhaTst">
                        {data.map(record => (
                            <Row record={record} />
                        ))}
                    </tbody>
                </table>
                <table className={classes.Sum}>
                    <HeadSum keys={keysSum} />
                    <tbody className="LinhaTst">
                        {dataTableSum.map(record => (
                            <RowSum record={record} />
                        ))}
                    </tbody>
                </table>
                <button className="button-excelexport" onClick={onDownload}>
                    Baixar dados da tabela em Excel
                </button>
            </div>
        </body>
    );
}
Aneel.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Aneel);
