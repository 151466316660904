// router path
export const LOGIN = "/login";
export const HOME = "/home";
export const PBTENSAO = "/painel/baixaTensao";
export const P_G_CLIENTE = "/painel/grandesClientes";
export const P_G_OUVIDORIA = "/painel/ouvidoria";
export const P_PROCON = "/painel/procon";
export const ADM_RESET_PASS = "/adm/redefinirSenha";
export const ADM_TIPOUSUARIO = "/adm/alterarTipoUsuario";
export const R_ANEEL = "/relatorios/aneel";
export const R_ORCAMENTO = "/relatorios/orçamento";
export const R_CCVMENSAL = "/relatorios/ccvMensal";
export const R_CCVANUAL = "/relatorios/ccvAnual";
export const R_BSCCHAMADAS = "/relatorios/bscChamadas";
export const R_ANEELMENSAL = "/relatorios/aneelMensal";
export const R_ANEELAGREGADO = "/relatorios/aneelMensalAgreg";
export const R_ANEELANUAL = "/relatorios/aneelAnual";
export const R_ANEELDETALHE = "/relatorios/aneelDetalheDiario";
export const R_ANEELANUALAGREG = "/relatorios/aneelAnualAgreg";
export const R_ANEELQUALIDADE = "/relatorios/aneelQualidadeAtend";
export const R_ANEELATENDIMENTO = "/relatorios/aneel/Atendimento";
export const R_BSCIAB = "/relatorios/bscIab";
export const R_BSCICO = "/relatorios/bscIco";
export const R_BSCINS = "/relatorios/bscIns";
export const R_BSCTMA = "/relatorios/bscTma";
export const R_BSCURA = "/relatorios/bscUra";
export const R_BSCRETURA = "/relatorios/bscRetUra";
export const R_BSCRETNUM = "/relatorios/bscRetNum";
export const R_BSCMINUTAGEM = "/relatorios/bscMinutagem";
export const R_BSCRECEBIDAS = "/relatorios/bscRecebidas";
export const R_BSCATENDIDAS = "/relatorios/bscAtendidas";
export const R_CUSTOTELEFONIA = "/relatorios/Custo0800";
export const R_CUSTODEMAIS = "/relatorios/DemaisCustos";
export const R_CUSTOCONSOLIDADO = "/relatorios/CustoConsolidado";
export const R_ORCAMENTOMENSAL = "/relatorios/OrcamentoMensal";
export const R_ORCAMENTOANUAL = "/relatorios/OrcamentoAnual";
