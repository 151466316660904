import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import logo from "../Home/logo.png";
import Tables from "../../Tables/TableBaixaTensao";
// import "./ouvidoria.css"

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center"
});

function ouvidoria() {
    return (
        <body>
            <div className="div-center2">
                <div className="header-flex">
                    <div className="paragrafo-header">
                        <h3>Painel</h3>
                        <p>Ouvidoria</p>
                    </div>
                </div>
                <hr></hr>
                <div className="dados-grandesclientes">
                    <div className="dados-tipicos2">
                        Dados Típicos
                        <p className="paragrafo-dados">
                            não há dados para exibição.
                        </p>
                    </div>

                    <div className="dados-gerais2">
                        Dados Gerais
                        <p className="paragrafo-dados">
                            não há dados para exibição.
                        </p>
                    </div>
                </div>
            </div>
        </body>
    );
}
ouvidoria.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ouvidoria);
