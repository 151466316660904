import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tables from "../../Tables/TableBSCAbandonadas";
import Select from "react-select";
import "./consolidado.css";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center"
});

function Consolidade() {
    const options = [
        { value: "2021", label: "2021" },
        { value: "2022", label: "2022" }
    ];
    const options2 = [{ value: "meses", label: "Meses Fechados" }];

    return (
        <body>
            <div className="responsive-geral">
                <div className="header-select">
                    <div className="header-agregado">
                        <h3>BSC</h3>
                        <p>
                            Relatório Custo Telefonia Consolidado (0800 + Demais
                            Custos)
                        </p>
                    </div>
                </div>
                <hr></hr>
                <div className="pesquisa-bsc">
                    <Select
                        placeholder="2022"
                        options={options}
                        isClearable={true}
                    />
                    <Select
                        placeholder="Meses Fechados"
                        options={options2}
                        isClearable={true}
                    />

                    <button className="button-aneel">CONSULTAR</button>
                </div>
            </div>
            <div className="table-agregado">
                <Tables />
            </div>
        </body>
    );
}
Consolidade.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Consolidade);
