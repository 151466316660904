import React from "react";
import PropTypes from "prop-types";

import DescriptionIcon from "@material-ui/icons/Description";

import ListItem1 from "../UI/ListItem/1";

const SidebarRelAneelItem = ({ onClick, selected }) => (
    <ListItem1
        icon={<DescriptionIcon />}
        text="Aneel"
        onClick={onClick}
        selected={selected}
    />
);

SidebarRelAneelItem.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

export default SidebarRelAneelItem;
