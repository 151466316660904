import React from "react";
import PropTypes from "prop-types";

import OutlinedFlagIcon from "@material-ui/icons/OutlinedFlag";
// import LoginIcon from '@mui/icons-material/Login';

import ListItem1 from "../UI/ListItem/1";

const SidebarLoginItem = ({ clickProp, selected }) => {
    const sendLogout = () => {
        clickProp({});
    };

    return (
        <ListItem1
            // icon={<OutlinedFlagIcon />}
            text="Sair"
            onClick={sendLogout}
            selected={selected}
        />
    );
};

SidebarLoginItem.propTypes = {
    selected: PropTypes.bool,
    clickProp: PropTypes.any
};

export default SidebarLoginItem;
