import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import logo from "../Home/logo.png";
import Tables from "../../Tables/TableBSCAbandonadas";
import Select from "react-select";
import "./custotelefonia.css";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center"
});

function Custo0800() {
    const options = [
        { value: "2021", label: "2021" },
        { value: "2022", label: "2022" }
    ];
    const options2 = [{ value: "meses", label: "Meses Fechados" }];

    return (
        <body>
            <div className="responsive-geral">
                <div className="header-select">
                    <div className="header-agregado">
                        <h3>BSC</h3>
                        <p>Relatório Custo Telefonia (0800)</p>
                    </div>
                </div>
                <hr></hr>
                <div className="pesquisa-bsc">
                    <Select
                        placeholder="2022"
                        options={options}
                        isClearable={true}
                    />
                    <Select
                        placeholder="Meses Fechados"
                        options={options2}
                        isClearable={true}
                    />

                    <button className="button-aneel">CONSULTAR</button>
                </div>
            </div>
            <div className="table-agregado">
                <Tables />
            </div>
        </body>
    );
}
Custo0800.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Custo0800);
