import axios from "axios";
import ToastService from "../ToastService";
const url = process.env.REACT_APP_URL_AXIOS;
const AdmService = {
    alteraSenhaUsuario: async function(id, pas) {
        return axios
            .post(`${url}/AlteraSenhaUsuario?id=${id}&s=${pas}`, {})
            .then(response => {
                ToastService.success(`Senha redefinida com sucesso.`);
                return response.data;
            })
            .catch(ex => {
                ToastService.default(`Falha ao redefinir senha. ${ex}`);
            });
    },
    listarUsuarios: async function() {
        return axios
            .post(`${url}/ListarUsuarios`, {})
            .then(response => {
                return response.data;
            })
            .catch(ex => {
                ToastService.default(`Falha ao listar usuários. ${ex}`);
                return `Falha da requisição ${ex.message}`;
            });
    },
    listarTipos: async function() {
        return axios
            .post(`${url}/ConsultaTiposUsuario`, {})
            .then(response => {
                return response.data;
            })
            .catch(ex => {
                ToastService.default(`Falha ao listar usuários. ${ex}`);
            });
    },
    salvarTipoUsuario: async function(id, tipo) {
        return axios
            .post(`${url}/AlteraTipoUsuario?id=${id}&t=${tipo}`, {})
            .then(response => {
                ToastService.success(`Usário atualizado.`);
                return response.data;
            })
            .catch(ex => {
                ToastService.default(`Falha ao atualizar usuário. ${ex}`);
            });
    }
};

export default AdmService;
