import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./AlterarTipoUsuario.css";

import { useState } from "react";
import AdmService from "../../services/adm/AdmService";
import ToastService from "../../services/ToastService";
import { Select } from "@material-ui/core";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center",
    home: {
        textAlign: "center",
        marginLeft: "80px",
        marginTop: "20px"
    },
    linha: {
        paddingTop: "20px"
    }
});

function alteraTipoUsuario({ classes }) {
    const [idUsuario, setIdUsuario] = useState("");
    const [idTipoUsuario, setIdTipoUsuario] = useState("");
    const [usuarios, setUsuarios] = useState([]);
    const [tipos, setTipos] = useState([]);

    useEffect(() => {
        carregarCombos();
    }, []);

    async function carregarCombos() {
        await AdmService.listarUsuarios().then(data => {
            setUsuarios(data);
        });
        await AdmService.listarTipos().then(data => {
            setTipos(data);
        });
    }
    function redef() {
        if (!idUsuario || !idTipoUsuario) {
            ToastService.warningLight(
                "É necessário selecionar todas as opções"
            );
            return false;
        }
        AdmService.salvarTipoUsuario(idUsuario, idTipoUsuario);
    }
    function handleIdUsuario(event) {
        setIdTipoUsuario(undefined);
        setIdUsuario(event.target.value);
        let usr = usuarios.find(function(x) {
            return x.uid == event.target.value;
        });
        if (usr.tipo != undefined) {
            setIdTipoUsuario(usr.tipo);
        }
    }
    function handleIdTipoUsuario(event) {
        setIdTipoUsuario(event.target.value);
    }

    return (
        <div>
            <div className="responsive-geral">
                <div className="header-select">
                    <div className="header-agregado">
                        <h3>Alterar Tipo de Usuário</h3>
                    </div>
                </div>
                <hr></hr>
                <div className="pesquisa-bsc">
                    <div className="d-flex">
                        <div className="form-group">
                            <label htmlFor="pas2">
                                <strong className="lbl">Usuário</strong>
                            </label>
                            <select
                                value={idUsuario}
                                onChange={handleIdUsuario}
                                className="input1"
                                id="usuarioSelecionado"
                                name="usuarioSelecionado"
                            >
                                <option value>Selecione..</option>
                                {usuarios.map(user => {
                                    return (
                                        <option key={user.uid} value={user.uid}>
                                            {user.alias}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="pas2">
                                <strong className="lbl">Tipo</strong>
                            </label>
                            {
                                <select
                                    value={idTipoUsuario}
                                    onChange={handleIdTipoUsuario}
                                    className="input1"
                                    id="tipoSelecionado"
                                    name="tipoSelecionado"
                                >
                                    <option value>Selecione..</option>
                                    {tipos.map(tipo => {
                                        return (
                                            <option
                                                value={tipo.tipo}
                                                key={tipo.tipo}
                                            >
                                                {tipo.descricao}
                                            </option>
                                        );
                                    })}
                                </select>
                            }
                        </div>
                        <div className="row">
                            <button onClick={redef} className="button-aneel">
                                {" "}
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

alteraTipoUsuario.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(alteraTipoUsuario);
