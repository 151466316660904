import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import MuiAppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import SidebarLoginItem from "../../containers/Sidebar/LoginItem";
import AppBarToolbar from "./Toolbar";
import "./appbar.css";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";

import color from "@material-ui/core/colors/amber";

const styles = theme => ({
    appBar: {
        position: "fixed",
        top: 0,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShifted: {
        marginLeft: 240,
        width: `calc(100% - ${240}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    drawHeader: {
        position: "absolute",
        marginTop: 24,
        width: 45,
        backgroundColor: "#029fc5",
        border: "none",
        color: "white",
        marginLeft: 1250,
        cursor: "pointer"
    }
});

const AppBar = ({ classes, shifted, userb, hideRoutes, loggedUser }) => {
    const sendLogout = us => {
        userb("");
    };

    let name = "";
    console.log(loggedUser);
    if (loggedUser === null || !loggedUser) {
        name = "Usuário Não Existente";
    } else {
        name = loggedUser.ALIAS;
    }
    function getFirstTwoWords(str) {
        return str
            .split(" ")
            .slice(0, 3)
            .join(" ");
    }

    const firstTwoWords = getFirstTwoWords(name);
    return (
        <div>
            {hideRoutes === true ? (
                <div></div>
            ) : (
                <MuiAppBar
                    position="absolute"
                    className={classNames(
                        classes.appBar,
                        shifted && classes.appBarShifted
                    )}
                >
                    <div className="toolbar-nome">
                        {firstTwoWords}

                        <Tooltip title="Sair">
                            <ExitToAppIcon
                                className="logo-expandLogin"
                                onClick={sendLogout}
                            ></ExitToAppIcon>
                        </Tooltip>
                    </div>
                    <AppBarToolbar shifted={shifted}></AppBarToolbar>
                </MuiAppBar>
            )}
        </div>
    );
};

AppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    shifted: PropTypes.bool
    // loggedUser: PropTypes.object.isRequired
};

export default withStyles(styles)(AppBar);
