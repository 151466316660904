import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./RedefinirSenha.css";
import AdmService from "../../services/adm/AdmService";
import ToastService from "../../services/ToastService";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center"
});

class RedefinirSenha extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        idUser: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            pass: "",
            pass1: "",
            loading: false
        };

        this.redef = this.redef.bind(this);
        this.handlePas = this.handlePas.bind(this);
        this.handlePas1 = this.handlePas1.bind(this);
    }
    redef() {
        if (this.state.pass !== this.state.pass1) {
            ToastService.warningLight("As senhas não conferem");
            return false;
        }

        AdmService.alteraSenhaUsuario(this.props.idUser, this.state.pass);
    }
    handlePas(event) {
        this.setState({ pass: event.target.value });
    }
    handlePas1(event) {
        this.setState({ pass1: event.target.value });
    }

    render() {
        return (
            <div>
                <div className="responsive-geral">
                    <div className="header-select">
                        <div className="header-agregado">
                            <h3>Alterar Senha</h3>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="pesquisa-bsc">
                        <div className="d-flex">
                            <div className="form-group">
                                <label htmlFor="pas1">
                                    <strong className="lbl">Nova senha</strong>
                                </label>
                                <input
                                    className="input1"
                                    type="password"
                                    onChange={this.handlePas}
                                    name="pas1"
                                    id="pas1"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="pas2">
                                    <strong className="lbl">
                                        Confirme a senha
                                    </strong>
                                </label>
                                <input
                                    className="input1"
                                    type="password"
                                    onChange={this.handlePas1}
                                    name="pas2"
                                    id="pas2"
                                />
                            </div>
                            <div className="row">
                                <button
                                    onClick={this.redef}
                                    className="button-aneel"
                                >
                                    {" "}
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(RedefinirSenha);
