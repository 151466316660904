// Libs
import { connect } from "react-redux";
import { push } from "connected-react-router";
// Component
import SidebarProconsItem from "../../components/Sidebar/ProconsItem";
// Constants
import { P_PROCON } from "../../constants/routes";
// Selectors
import history from "../../history";

import { isItemSelected } from "../../redux/selectors/ui";

const mapStateToProps = state => ({
    selected: isItemSelected(state)
});

const mapDispatchToProps = {
    onClick: () => history.push(P_PROCON)
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarProconsItem);
