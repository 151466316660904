import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import logo from "../Home/logo.png";
import Tables from "../../Tables/TableBaixaTensao";
import "./baixaTensao.css";
import axios from "axios";
import RemoveIcon from "@material-ui/icons/Remove";
import history from "../../history";
import { R_ANEELMENSAL } from "../../constants/routes";
import "./table.css";
import { useDownloadExcel } from "react-export-table-to-excel";
// import firebase from "firebase";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import crypto from "crypto";

import { useEffect, useState, useCallback } from "react";
import {
    LineChart,
    XAxis,
    Tooltip,
    CartesianGrid,
    Line,
    YAxis,
    BarChart,
    Bar,
    ReferenceLine,
    Legend,
    LabelList,
    Label,
    Cell
} from "recharts";
import Select from "react-select";
import { ContactSupportOutlined } from "@material-ui/icons";

// import IMG from "../../assets/1997C3F6-4E25-479F-9B0A-0B6E1078A355.png"
const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center",
    home: {
        textAlign: "center",
        marginLeft: "80px",
        marginTop: "20px"
    },
    linha: {
        paddingTop: "20px"
    }
});

function baixaTensao({ classes }) {
    var [date, setDate] = useState();
    const [setinfos, setInfosTabela] = useState([]);
    const [setInfosGrf, setInfosGrafico] = useState([]);
    const [curr, setCurr] = useState([]);
    var [infoTipicidade, setinfoTipicidade] = useState([]);
    var [empresaDados, setEmpresaDados] = useState("");
    const axios = require("axios");

    // async function getBearerToken() {
    //     const clientId = process.env.REACT_APP_CLIENT_ID;
    //     const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

    //     const timestamp = Math.floor(Date.now() / 1000);
    //     const nonce = crypto.randomBytes(16).toString("hex");

    //     const body = new URLSearchParams();
    //     body.append("grant_type", "client_credentials");
    //     body.append("scope", "gestoronline");

    //     const config = {
    //         headers: {
    //             "Content-Type": "application/x-www-form-urlencoded",
    //             Authorization: `Basic ${Buffer.from(
    //                 `${clientId}:${clientSecret}`
    //             ).toString("base64")}`,
    //             "X-Timestamp": timestamp,
    //             "X-Nonce": nonce
    //         }
    //     };

    //     try {
    //         const response = await axios.post(
    //             "https://161.97.71.206/connect/token",
    //             body.toString(),
    //             config
    //         );
    //         return response.data.access_token;
    //     } catch (error) {
    //         console.error(error);
    //         return null;
    //     }
    // }

    // const testeChange = () => {
    //     conmsole
    // }
    if (!date) {
        date = curr;
    }
    async function SearchData() {
        // const token = await getBearerToken();
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalGraficoInsEmpresas?dt1=" +
                    date +
                    "&dt2=" +
                    date +
                    "&tp=" +
                    infoTipicidade,
                {}
            )
            .then(response => {
                setInfosGrafico(response.data);
                console.log(response.data);
                console.log(curr);
            });

        axios
            .post(
                "https://161.97.71.206/baixatensao?dt1=" +
                    date +
                    "&dt2=" +
                    date,
                {}
                // ?dt1=2022-11-09&dt2=2022-11-09"
            )
            .then(response => {
                setInfosTabela(response.data);
                console.log(response.data);
            });
    }

    if (infoTipicidade == "") {
        var infoTipicidade = "Geral";
    }

    const [count, setCount] = useState(0);

    useEffect(() => {
        const dataNow = new Date();
        const dia = String(dataNow.getDate()).padStart(2, "0");
        const mes = String(dataNow.getMonth() + 1).padStart(2, "0");
        const ano = dataNow.getFullYear();
        const dataAtual = ano + "-" + mes + "-" + dia;
        setCurr(dataAtual);

        const execute = () => {
            if (empresaDados != "") {
                history.push(R_ANEELMENSAL, {
                    infoTipicidade,
                    date,
                    empresaDados
                });
            }
        };

        const interval = setInterval(() => {
            setCount(count + 1);
        }, 20000);
        // const token = getBearerToken();

        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalGraficoInsEmpresas?dt1=" +
                    date +
                    "&dt2=" +
                    date +
                    "&tp=" +
                    infoTipicidade,
                {}
            )
            .then(response => {
                setInfosGrafico(response.data);
                console.log(response.data);
                console.log(curr);
            });

        axios
            .post(
                "https://161.97.71.206/baixatensao?dt1=" +
                    date +
                    "&dt2=" +
                    date,
                {}
                // ?dt1=2022-11-09&dt2=2022-11-09"
            )
            .then(response => {
                setInfosTabela(response.data);
                console.log(response.data);
            });
        execute();
        return () => clearInterval(interval);
    }, [curr, count, empresaDados]);

    var infodata = setInfosGrf.map(repo => {
        if (repo["INS 85-30"] < 85) {
        }
        return repo;
    });

    if (setInfosGrf == "") {
        var infodata = [
            {
                SIGLA: "EMT"
            },
            {
                SIGLA: "ETO"
            },
            {
                SIGLA: "EMS"
            },
            {
                SIGLA: "EPB"
            },
            {
                SIGLA: "EBO"
            },
            {
                SIGLA: "ESE"
            },
            {
                SIGLA: "EMG"
            },
            {
                SIGLA: "ENF"
            },
            {
                SIGLA: "ESS"
            },
            {
                SIGLA: "EAC"
            },
            {
                SIGLA: "ERO"
            }
        ];
    }

    const data = [
        {
            Empresa: setinfos.map(repo => {
                return (
                    <p className="LinhaTstEmp">
                        <img src={"/images/" + repo.Logotipo}></img>
                    </p>
                );
            }),
            COF: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.COF}</p>;
            }),
            CO: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.CO}</p>;
            }),
            "Ch Ura": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Ch Ura"]}</p>;
            }),
            "Ca < 30s": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Ca < 30s"]}</p>;
            }),
            "Ca > 30s": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Ca > 30s"]}</p>;
            }),
            CA: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.Ca}</p>;
            }),
            "Cab < 30s": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Cab < 30s"]}</p>;
            }),
            "Cab > 30s": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Cab > 30s"]}</p>;
            }),
            Cab: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.Cab}</p>;
            }),
            "CR Rec": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["CR Rec"]}</p>;
            }),
            "CR Plan": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["CR Plan"]}</p>;
            }),
            "INS 85-30": setinfos.map(repo => {
                if (repo["INS 85-30"] > 85) {
                    return (
                        <p className="LinhaTst borderInsGreen">
                            {repo["INS 85-30"] + "%"}
                        </p>
                    );
                } else {
                    return (
                        <p className="LinhaTst borderInsRed">
                            {repo["INS 85-30"] + "%"}
                        </p>
                    );
                }
            }),
            "IAB %": setinfos.map(repo => {
                if (repo["IAB %"] > 4) {
                    return (
                        <p className="LinhaTst borderIabGreen">
                            {repo["IAB %"] + "%"}
                        </p>
                    );
                } else {
                    return (
                        <p className="LinhaTst borderIabRed">
                            {repo["IAB %"] + "%"}
                        </p>
                    );
                }
            }),
            "CR (Pla x Rec)": setinfos.map(repo => {
                return (
                    <p className="LinhaTst">{repo["CR (Pla x Rec)"] + "%"}</p>
                );
            }),
            "Cat/Rec %": setinfos.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Cat/Rec %"].toFixed(2) + "%"}
                    </p>
                );
            }),
            TMA: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.TMA}</p>;
            }),
            TMAb: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.TMAb}</p>;
            }),
            TME: setinfos.map(repo => {
                return <p className="LinhaTst">{repo.TME}</p>;
            }),
            "Agt.": setinfos.map(repo => {
                return <p className="LinhaTst">{repo["Agt."]}</p>;
            }),
            Limite: setinfos.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.Limite.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            })
        }
    ];

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "DadosBaixaTensão" + date,
        sheet: "DadosBaixaTensão" + date
    });

    const Head = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const Row = ({ record }) => {
        return (
            <tr className="testeTABE" key={record.id}>
                {keys.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };
    const options = [
        { value: "Geral", label: "Dados Gerais" },
        { value: "Típico", label: "Dados Típicos" },
        { value: "Atípico", label: "Dados Atípicos" }
    ];

    const keys = Object.keys(data[0]);

    const getOptionValue = dadosValue => {
        return dadosValue;
    };

    const handleChange = selected => {
        console.log(selected);
        setinfoTipicidade(selected.value);
    };

    const colors = ["#00cce2", "#ffc27d"];

    const handleCellClick = entry => {
        setEmpresaDados(entry.ID);
        console.log(empresaDados);
    };

    return (
        <body>
            <div className="div-center">
                <div className="header-flex">
                    <div className="header-flexstart">
                        <h3>Painel</h3>
                        <p>Baixa Tensão</p>
                    </div>
                    <Select
                        placeholder="Dados Gerais"
                        className="selectreact"
                        options={options}
                        // getOptionLabel={getOptionLabel}
                        // getOptionValue={getOptionValue}
                        onChange={handleChange}
                    />
                </div>
                <hr className="hr-header"></hr>
                <div className="dados-tipicos">Dados Típicos</div>

                <div className="linechart-flex">
                    <p className="LineHorizontal">INS %</p>

                    <BarChart
                        width={1250}
                        height={220}
                        data={infodata}
                        barSize={47}
                        margin={{ bottom: 20 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <ReferenceLine
                            strokeWidth={5}
                            y={85}
                            stroke="rgb(250, 209, 73)"
                            strokeDasharray="0"
                            isFront
                        />
                        <XAxis padding={{ bottom: 12 }} dataKey="SIGLA">
                            <Label
                                value="Empresa"
                                offset={-15}
                                position="insideBottom"
                            />
                        </XAxis>

                        <YAxis padding={{ top: 18 }} />

                        <Bar
                            // onClick={() => history.push(R_ANEELMENSAL, { infoTipicidade, date, empresaDados })}
                            isAnimationActive={false}
                            label={false}
                            dataKey="INS 85-30"
                            fill="#00cce2"
                            className="hover-bar"
                        >
                            <LabelList
                                dataKey="INS 85-30"
                                position="top"
                                style={{ fill: "black" }}
                            />
                            {infodata.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={
                                        entry["INS 85-30"] < 85
                                            ? colors[1]
                                            : colors[0]
                                    }
                                    onClick={() => handleCellClick(entry)}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </div>
                <div className="sinopse-main">
                    <div className="header-flexicontst">
                        <div className="header-flex">
                            <RemoveIcon className="IconLine" />{" "}
                            <p className="DownLine">Limite INS 85%</p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine2" />
                            <p className="DownLine">Dia Típico</p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine3" />
                            <p className="DownLine">
                                Dia Típico - INS Abaixo de 85%
                            </p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine4" />
                            <p className="DownLine">Dia Atípico</p>
                        </div>
                    </div>

                    {/* <div>🟡 Dia Típico</div>
                    <div>🟡 Dia Típico - INS Abaixo de 85%</div>
                    <div>🔵 Dia Atípico</div> */}
                </div>
                <hr className="hr-dadosgerais"></hr>
                <div className="div-table">
                    <div className="dados-gerais">
                        <div className="header-flexstart">Dados Gerais</div>

                        <div className="date-right">
                            <input
                                value={date}
                                onChange={e => setDate(e.target.value)}
                                type="date"
                                className="input-date datePicker"
                            ></input>
                            <button
                                // value={date}
                                onClick={SearchData}
                                className="button-consultar"
                            >
                                CONSULTAR
                            </button>
                            {/* <button onClick={() => history.push(R_ANEELMENSAL, { infoTipicidade, date, empresaDados })} className="buttonc-consultar">TESTENOW</button> */}
                        </div>
                    </div>

                    <div>
                        <table ref={tableRef} className={classes.home}>
                            <Head keys={keys} />
                            <tbody className="LinhaTst">
                                {data.map(record => (
                                    <Row record={record} />
                                ))}
                            </tbody>
                        </table>
                        <button
                            className="button-excelexport"
                            onClick={onDownload}
                        >
                            Baixar dados da tabela em Excel
                        </button>
                    </div>
                </div>
            </div>
        </body>
    );
}

baixaTensao.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(baixaTensao);
