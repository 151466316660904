import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import logo from "../Home/logo.png";
import Tables from "../../Tables/TableCCVEmpresa";
import TablesCCVVirtual from "../../Tables/TableCCVVirtual";
import TablesCallCenter from "../../Tables/TableCCVCallCenter";
import TablesCC from "../../Tables/TableCC+CCV";
import TablesPropAtend from "../../Tables/TableCCVPropAtend";
import TablesPropMinut from "../../Tables/TableCCVPropMinut";

import "./ccvanual.css";
import Select from "react-select";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center"
});

function ccvanual() {
    const options = [{ value: "baixatensao", label: "Todas Empresas" }];
    const options2 = [{ value: "ano", label: "2022" }];
    const options3 = [{ value: "meses", label: "Meses Fechados" }];
    const options4 = [{ value: "ano", label: "CCV Ativo" }];

    return (
        <body>
            <div className="responsive-geral">
                <div className="header-select">
                    <div className="header-agregado">
                        <h3>CCV</h3>
                        <p>
                            Relatório de Atendimento CCV e Call Center (Anual)
                        </p>
                    </div>
                </div>
                <hr></hr>
                <div className="pesquisa-ccv">
                    <Select
                        placeholder="Todas Empresas"
                        options={options}
                        isClearable={true}
                    />
                    <Select
                        placeholder="2022"
                        options={options2}
                        isClearable={true}
                    />
                    <Select
                        placeholder="Meses Fechados"
                        options={options3}
                        isClearable={true}
                    />
                    <Select
                        placeholder="CCV Ativo"
                        options={options4}
                        isClearable={true}
                    />

                    <button className="button-aneel">CONSULTAR</button>
                </div>
            </div>
            <div className="dados-tipicosmensal">Todas Empresas (2022)</div>
            <div className="table-ccv">
                <Tables />
                <TablesCCVVirtual />
                <TablesCallCenter />
                <TablesCC />
                <TablesPropAtend />
                <TablesPropMinut />
            </div>
        </body>
    );
}
ccvanual.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ccvanual);
