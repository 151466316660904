import React from "react";
import PropTypes from "prop-types";

import Toolbar from "@material-ui/core/Toolbar";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import "./appbar.css";
import SidebarLoginItem from "../../containers/Sidebar/LoginItem";

import logoener from "../Home/logoener2.png";

const AppBarToolbar = ({ width, shifted, userb }) => {
    const sendLogout = us => {
        userb("");
    };

    return (
        <div className="toolbar-color">
            <Toolbar disableGutters={!shifted || isWidthUp("md", width)}>
                <div className="appbar-title">
                    <img className="logo-expand" src={logoener}></img>
                </div>
                {/* <AppBarTitle /> */}
            </Toolbar>
        </div>
    );
};

AppBarToolbar.propTypes = {
    width: PropTypes.string.isRequired,
    shifted: PropTypes.bool,
    userb: PropTypes.any
};

export default withWidth()(AppBarToolbar);
