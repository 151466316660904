import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./aneel.css";
import Select from "react-select";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center"
});

function Aneel() {
    const options = [
        { value: "baixatensao", label: "Baixa Tensão" },
        { value: "grandesclientes", label: "Grandes Clientes" },
        { value: "ouvidoria", label: "Ouvidoria" },
        { value: "procon", label: "Procon" }
    ];
    const options2 = [{ value: "empresa", label: "Empresa" }];
    const options3 = [{ value: "data", label: "Mês" }];
    const options4 = [{ value: "ano", label: "Ano" }];
    const options5 = [{ value: "tipicas", label: "Tipicas" }];

    return (
        <div>
            <div className="responsive-geral">
                <div className="header-select">
                    <div className="header-aneel">
                        <h3>Aneel</h3>
                        <p>Relatório Mensal</p>
                    </div>
                    <div className="end-header">
                        <Select
                            placeholder="Baixa Tensão"
                            options={options}
                            isClearable={true}
                        />
                    </div>
                </div>
                <hr></hr>
                <div className="pesquisa-aneel">
                    <Select
                        placeholder="Baixa Tensão"
                        options={options}
                        isClearable={true}
                    />
                    <Select
                        placeholder="Empresa"
                        options={options2}
                        isClearable={true}
                    />
                    <Select
                        placeholder="Mês"
                        options={options3}
                        isClearable={true}
                    />
                    <Select
                        placeholder="Ano"
                        options={options4}
                        isClearable={true}
                    />
                    <Select
                        placeholder="Típicas"
                        options={options5}
                        isClearable={true}
                    />
                    <button className="button-aneel">CONSULTAR</button>
                </div>
            </div>
        </div>
    );
}
Aneel.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Aneel);
