import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    home: {
        textAlign: "center",
        marginLeft: "25px",
        marginTop: "20px"
    }
});

function tableMensalAgregado({ classes }) {
    const data = [
        {
            "CA ATEN": "8.534"
        },
        {
            "CA ATEN": "8.534"
        },
        {
            "CA ATEN": "8.534"
        },
        {
            "CA ATEN": "8.534"
        },
        {
            "CA ATEN": "8.534"
        },
        {
            "CA ATEN": "8.534"
        },
        {
            "CA ATEN": "8.534"
        },
        {
            "CA ATEN": "8.534"
        },
        {
            "CA ATEN": "8.534"
        }
    ];

    const Head = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const Row = ({ record }) => {
        return (
            <tr className="" key={record.id}>
                {keys.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    const keys = Object.keys(data[0]);

    return (
        <table className={classes.home}>
            <Head keys={keys} />
            <tbody className="tb-content">
                {data.map(record => (
                    <Row record={record} />
                ))}
            </tbody>
        </table>
    );
}

tableMensalAgregado.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(tableMensalAgregado);
