// Libs
import { connect } from "react-redux";
import SidebarLoginItem from "../../components/Sidebar/LoginItem";
// Constants
// Selectors
import { isItemSelected } from "../../redux/selectors/ui";

const mapStateToProps = state => ({
    selected: isItemSelected(state)
});

export default connect(mapStateToProps)(SidebarLoginItem);
