import { createStore } from "redux";

import _enhancer from "./enhancers";
import createRootReducer from "./reducers";
import { history } from "./enhancers/middlewares/router";

export default (
    reducer = createRootReducer(history),
    preloadedState = undefined,
    enhancer = _enhancer
) => createStore(reducer, preloadedState, enhancer);
