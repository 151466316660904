// Libs
import { connect } from "react-redux";
import { push } from "connected-react-router";
// Component
import SidebarGranClientesItem from "../../components/Sidebar/GranClientesItem";
// Constants
import { P_G_CLIENTE } from "../../constants/routes";
// Selectors
import { isItemSelected } from "../../redux/selectors/ui";
import history from "../../history";

const mapStateToProps = state => ({
    selected: isItemSelected(state)
});

const mapDispatchToProps = {
    onClick: () => history.push(P_G_CLIENTE)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidebarGranClientesItem);
