import React from "react";
import PropTypes from "prop-types";

import PieChartIcon from "@material-ui/icons/PieChart";

import ListItem1 from "../UI/ListItem/1";

const SidebarOrcamentoAnualItem = ({ onClick, selected }) => (
    <ListItem1 text="Anual" onClick={onClick} selected={selected} />
);

SidebarOrcamentoAnualItem.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

export default SidebarOrcamentoAnualItem;
