// Libs
import { connect } from "react-redux";
import { push } from "connected-react-router";
// Component
import RelatorioAneelAtendimentoItem from "../../components/Sidebar/RelAneelAtendimentoItem";
// Constants
import { R_ANEELATENDIMENTO } from "../../constants/routes";
// Selectors
import { isItemSelected } from "../../redux/selectors/ui";
import history from "../../history";

const mapStateToProps = state => ({
    selected: isItemSelected(state)
});

const mapDispatchToProps = {
    onClick: () => history.push(R_ANEELATENDIMENTO)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RelatorioAneelAtendimentoItem);
