import React from "react";
import PropTypes from "prop-types";

import PieChartIcon from "@material-ui/icons/PieChart";

import ListItem1 from "../UI/ListItem/1";

const SidebarProconsItem = ({ onClick, selected }) => (
    <ListItem1 text="Procons" onClick={onClick} selected={selected} />
);

SidebarProconsItem.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

export default SidebarProconsItem;
