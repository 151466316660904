import React from "react";
import PropTypes from "prop-types";
import { LineChart, XAxis, Tooltip, CartesianGrid, Line } from "recharts";

import PieChartIcon from "@material-ui/icons/PieChart";

import ListItem1 from "../UI/ListItem/1";
import { withStyles } from "@material-ui/core/styles";
import "./home.css";
import logo from "./logo.png";

const styles = theme => ({
    home: {
        textAlign: "center"
    },
    pad: {
        marginLeft: "34%"
    },
    homeLogo: {
        height: "280px",
        width: "340px"
    },
    homeHeader: {
        height: "150px",
        // padding: "0px",
        marginTop: "-20px"
    },
    homeTitle: {
        fontSize: "1.5em"
    },
    homeIntro: {
        fontSize: "large"
    }
});

const Home = ({ classes, onClick, selected, loggedUser }) => (
    <div className={classes.home}>
        <div className={classes.homeHeader}>
            <img src={logo} className={classes.homeLogo} alt="logo" />
        </div>
        <div className="boas-vindas">
            Bem-Vindo ao sistema Gestor Online.<br></br>
        </div>
        No menu ao lado esquerdo você terá acessos aos Paineis e aos Relatórios.
        <br />
        <div className="paineisborder">
            {loggedUser.UID} - {loggedUser.ALIAS} - {loggedUser.TIPO} -{" "}
            {loggedUser.ATIVO} <br />
        </div>
        <div className="list-itemteste"></div>
        {/* <div className="paineisborder">
                Relatórios
            </div>
            <div className="list-relatorio">
                <ListItem1
                    text="Aneel"
                    onClick={onClick}
                    selected={selected}
                />

            </div> */}
        <div className="rodape-home">
            *** Esta tela servirá para ser a home page do sistema, a forma atual
            é totalmente provisória, pois ainda será discutido a forma como será
            feita. ***
        </div>
    </div>
);

Home.propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    loggedUser: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
