import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./aneelqualidade.css";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import "./aneelqualidade.css";
import crypto from "crypto";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center",
    home: {
        textAlign: "center",
        marginLeft: "80px",
        marginTop: "20px"
    },
    linha: {
        paddingTop: "20px"
    }
});

function Aneel({ classes }) {
    const [setInfos, setInfosTabelaQualidade] = useState([]);
    const [setInfosDiario, setInfosTabelaQualidadeDiario] = useState([]);
    const [date, setDate] = useState();
    const [dateFim, setDateFim] = useState();
    const [Empresa, setEmpresa] = useState();
    const [Tipicidade, setTipicidade] = useState();
    const [curr, setCurrQualidade] = useState([]);
    const [mestst, setMesTst] = useState([]);
    const [empresaLabel, setEmpresaLabel] = useState();
    const [anoLabel, setAnoLabel] = useState();
    const [tipicidadeLabel, setTipicidadeLabel] = useState();
    const [exibirDados, setExibirDados] = useState(false);

    const axios = require("axios");

    async function getBearerToken() {
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

        const timestamp = Math.floor(Date.now() / 1000);
        const nonce = crypto.randomBytes(16).toString("hex");

        const body = new URLSearchParams();
        body.append("grant_type", "client_credentials");
        body.append("scope", "gestoronline");

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${Buffer.from(
                    `${clientId}:${clientSecret}`
                ).toString("base64")}`,
                "X-Timestamp": timestamp,
                "X-Nonce": nonce
            }
        };

        try {
            const response = await axios.post(
                "https://161.97.71.206/connect/token",
                body.toString(),
                config
            );
            return response.data.access_token;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    useEffect(() => {
        // const dataNow = new Date();
        // const dia = String(dataNow.getDate()).padStart(2, '0');
        // const mes = String(dataNow.getMonth() + 1).padStart(2, '0');
        // const ano = dataNow.getFullYear();
        // const dataAtual = ano + '-' + mes + '-' + dia;
        // setCurrQualidade(dataAtual);

        // axios
        // .get(
        //     "http://wsrq001:8080/api/AneelRelatorioQualidadeAtendimentoDiarioDadosGerais?id_empresa=1&dt1="+curr+"&dt2="+curr+"&tp=Típico"
        //     // "http://wsrq001:8080/api/AneelRelatorioQualidadeAtendimentoDiarioDadosGerais?id_empresa=1&dt1=2022-11-01&dt2=2022-11-30&tp=Típico"
        // )
        // .then(response => {
        //     setInfosTabelaQualidadeDiario(response.data);
        //     console.log(response.data);
        // });
        // axios.get(
        //     "http://wsrq001:8080/api/AneelRelatorioQualidadeAtendimentoDiario?id_empresa=1&dt1="+curr+"&dt2="+curr+"&tp=Típico"
        // )
        // .then(response => {
        //     setInfosTabelaQualidade(response.data);
        //     console.log(response.data);
        // });
        "";
    });

    const options = [
        { value: "1", label: "Energisa Mato Grosso" },
        { value: "2", label: "Energisa Tocantins" },
        { value: "1002", label: "Energisa Mato Grosso do Sul" },
        { value: "1008", label: "Energisa Paraíba" },
        { value: "1009", label: "Energisa Borborema" },
        { value: "1010", label: "Energisa Sergipe" },
        { value: "1011", label: "Energisa Minas Gerais" },
        { value: "1012", label: "Energisa Nova Friburgo" },
        { value: "1013", label: "Energisa Sul Sudeste" },
        { value: "1014", label: "Energisa Acre" },
        { value: "1015", label: "Energisa Rondônia" }
    ];
    const options4 = [
        { value: "Todos", label: "Todos" },
        { value: "Típico", label: "Típico" },
        { value: "Atípico", label: "Atípico" }
    ];

    const data = [
        {
            Data: setInfos.map(repo => {
                return <p className="LinhaDiario">{repo.DATA}</p>;
            }),
            "Intervalo Início": "",
            "Intervalo Fim": "TOTAL",
            Tipicidade: "Típico",
            "Ca < 30s": setInfos.map(repo => {
                return <p>{repo["Ca < 30s"]}</p>;
            }),
            "Ca > 30s": setInfos.map(repo => {
                return <p>{repo["Ca > 30s"]}</p>;
            }),
            "Cab < 30s": setInfos.map(repo => {
                return <p>{repo["Cab < 30s"]}</p>;
            }),
            "Cab > 30s": setInfos.map(repo => {
                return <p>{repo["Cab > 30s"]}</p>;
            }),
            CO: setInfos.map(repo => {
                return <p>{repo.CO}</p>;
            }),
            COF: setInfos.map(repo => {
                return <p>{repo.COF}</p>;
            }),
            TMA: setInfos.map(repo => {
                return <p>{repo.TMA}</p>;
            }),
            Agentes: "22",
            "INS 30s": setInfos.map(repo => {
                return <p>{repo["INS 30s"].toFixed(2)}</p>;
            }),
            "INS 60s": setInfos.map(repo => {
                return <p>{repo["INS 60s"].toFixed(2)}</p>;
            }),
            "IAB %": "1,14%",
            "ICO % ": "0,00%",
            Limite: setInfos.map(repo => {
                return <p>{repo.Limite}</p>;
            })
        }
    ];

    const Head = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const Row = ({ record }) => {
        return (
            <tr className="" key={record.id}>
                {keys.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    async function FiltrarDados() {
        const token = await getBearerToken();
        setExibirDados(true);
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioQualidadeAtendimentoDiarioDadosGerais?id_empresa=" +
                    Empresa +
                    "&dt1=" +
                    date +
                    "&dt2=" +
                    dateFim +
                    "&tp=" +
                    Tipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
                // "http://wsrq001:8080/api/AneelRelatorioQualidadeAtendimentoDiarioDadosGerais?id_empresa=1&dt1=2022-11-01&dt2=2022-11-30&tp=Típico"
            )
            .then(response => {
                setInfosTabelaQualidadeDiario(response.data);
                console.log(response.data);
            });
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioQualidadeAtendimentoDiario?id_empresa=" +
                    Empresa +
                    "&dt1=" +
                    date +
                    "&dt2=" +
                    dateFim +
                    "&tp=" +
                    Tipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosTabelaQualidade(response.data);
                console.log(response.data);
            });
    }

    const keys = Object.keys(data[0]);
    const dataDiario = [
        {
            Data: setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo.DATA}</p>;
            }),
            "Intervalo Início": setInfosDiario.map(repo => {
                return (
                    <p className="LinhaDiario">{repo["Intervalo Início"]}</p>
                );
            }),
            "Intervalo Fim": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["Intervalo Fim"]}</p>;
            }),
            Tipicidade: setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo.Tipicidade}</p>;
            }),
            "Ca < 30s": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["Ca > 30s"]}</p>;
            }),
            "Ca > 30s": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["Ca > 30s"]}</p>;
            }),
            "Cab < 30s": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["Cab < 30s"]}</p>;
            }),
            "Cab > 30s": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["Cab > 30s"]}</p>;
            }),
            CO: setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo.CO}</p>;
            }),
            COF: setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo.COF}</p>;
            }),
            TMA: setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo.TMA}</p>;
            }),
            Agentes: setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo.Agente}</p>;
            }),
            "INS 30s": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["INS 30s"]}</p>;
            }),
            "INS 60s": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["INS 60s"]}</p>;
            }),
            "IAB %": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["IAB %"]}</p>;
            }),
            "ICO % ": setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo["ICO %"]}</p>;
            }),
            Limite: setInfosDiario.map(repo => {
                return <p className="LinhaDiario">{repo.Limite}</p>;
            })
        }
    ];

    const HeadDiario = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const RowDiario = ({ record }) => {
        return (
            <tr className="" key={record.id}>
                {keysDiario.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    const handleEmpresas = selected => {
        console.log(selected);
        setEmpresa(selected.value);
        setEmpresaLabel(selected.label);
    };
    const handleTipicidade = selected => {
        console.log(selected);
        setTipicidade(selected.value);
        setTipicidadeLabel(selected.label);
    };

    const keysDiario = Object.keys(dataDiario[0]);

    const tableRef2 = useRef(null);
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef2.current,
        filename: "DadosQualidadeAtend" + date,
        sheet: "DadosQualidadeAtend" + date
    });

    return (
        <body>
            <div className="responsive-geral">
                <div className="header-select">
                    <div className="header-agregadoQualid">
                        <h3>Aneel</h3>
                        <p>Qualidade de Atendimento Telefônico Diário ANEEL</p>
                    </div>
                </div>
                <hr></hr>
                <div className="pesquisa-agregado2">
                    <div className="input-flexcolumn">
                        Empresa
                        <Select
                            placeholder="Empresa"
                            options={options}
                            // isClearable={true}
                            // value={Empresa}
                            onChange={handleEmpresas}
                        />
                    </div>

                    <div className="input-flexcolumn">
                        Data Inicial
                        <input
                            value={date}
                            onChange={d => setDate(d.target.value)}
                            type="date"
                            className="input-dateQual "
                        ></input>
                    </div>
                    <div className="input-flexcolumn">
                        Data Final
                        <input
                            value={dateFim}
                            onChange={s => setDateFim(s.target.value)}
                            type="date"
                            className="input-dateQual"
                        ></input>
                    </div>
                    {/* <select onChange={(a) => setMesTst(a.target.value)}>
                        
                        <option value="" selected disabled >Mês</option>
                    <option value="Fevereiro">
                            Fevereiro
                        </option>
                        <option value="Março">
                        Março
                        </option>
                        </select> */}
                    {/* <select onChange={(v) => setTipicidade(v.target.value)}>
                        <option value="" selected disabled >Tipicidade</option>
                        <option value="Todos">
                            Todos
                        </option>
                        <option value="Típico">
                            Típico
                        </option>
                        <option value="Atípico">
                            Atípico
                        </option>
                    </select> */}
                    <div className="input-flexcolumn">
                        Tipicidade
                        <Select
                            placeholder="Tipicidade"
                            options={options4}
                            // isClearable={true}
                            // value={Empresa}
                            onChange={handleTipicidade}
                        />
                    </div>

                    <button onClick={FiltrarDados} className="button-aneel">
                        CONSULTAR
                    </button>
                </div>
            </div>
            {exibirDados && (
                <div className="dados-tipicosmensal">
                    {empresaLabel} - {anoLabel} ({tipicidadeLabel})
                </div>
            )}
            <div className="table-agregado2">
                <table className={classes.home}>
                    <Head keys={keys} />
                    <tbody className="tb-content">
                        {data.map(record => (
                            <Row record={record} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="table-agregado">
                <table ref={tableRef2} className={classes.home}>
                    <HeadDiario keys={keysDiario} />
                    <tbody className="tb-content">
                        {dataDiario.map(record => (
                            <RowDiario record={record} />
                        ))}
                    </tbody>
                </table>
                <button className="button-excelexport" onClick={onDownload}>
                    Baixar dados da tabela em Excel
                </button>
            </div>
        </body>
    );
}
Aneel.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Aneel);
