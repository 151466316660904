import React from "react";
import PropTypes from "prop-types";

import PieChartIcon from "@material-ui/icons/PieChart";

import ListItem1 from "../UI/ListItem/1";

const SidebarBSCRETNUMITEM = ({ onClick, selected }) => (
    <ListItem1
        icon={<PieChartIcon />}
        text="Ret Ura Núm"
        onClick={onClick}
        selected={selected}
    />
);

SidebarBSCRETNUMITEM.propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

export default SidebarBSCRETNUMITEM;
