import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import history from "../../history";

import { withStyles } from "@material-ui/core/styles";
import LayoutContent from "../../containers/Layout/Content";
import Sidebar from "../../containers/Sidebar/Sidebar";
import AppBar from "../../containers/AppBar/AppBar";
import Login from "../../components/Login/Login";
import { useCookies } from "react-cookie";

const styles = theme => ({
    frame: {
        position: "relative",
        display: "flex",
        width: "100%",
        minHeight: "100%"
    },
    content: {
        width: "100%",
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        minHeight: "calc(100% - 56px)",
        marginTop: 56,
        [theme.breakpoints.up("sm")]: {
            minHeight: "calc(100% - 64px)",
            marginTop: 64
        }
    }
});

function LayoutSkeleton() {
    const [cookies, setCookie] = useCookies(["user"]);
    const [hideRoutes, setHideRoutes] = useState(cookies.hideRoutes);
    const [loggedUser, setLogged] = useState(cookies.loggedUser);

    let logoutTimer;

    function checkCookies() {
        const histo = history.location.pathname;
        console.log(loggedUser, cookies);
        try {
            if (histo === "/login") {
                if (loggedUser === null || loggedUser === undefined) {
                    setHideRoutes(true);
                    return false;
                } else if (loggedUser.UID >= 0) {
                    console.log("logged user");
                    console.log(loggedUser);
                    setHideRoutes(false);
                    history.push("/painel/baixaTensao");
                }
            } else {
                if (loggedUser.UID === undefined) {
                    redirectToLogin();
                } else {
                    resetLogoutTimer();
                }
            }
        } catch (e) {
            console.error(e);
            redirectToLogin();
        }
    }

    const resetLogoutTimer = () => {
        clearTimeout(logoutTimer);
        logoutTimer = setTimeout(() => {
            setHideRoutes(true);
            history.push("/login");
        }, 1200000);
    };

    const redirectToLogin = () => {
        setHideRoutes(true);
        history.push("/login");
    };

    const setUsr = user => {
        const histo = history.location.pathname;
        let expires = new Date();
        expires.setTime(expires.getTime() + 36969999 * 1000);
        setCookie("loggedUser", user, { path: "/", expires });
        setLogged(user);
        if (user === "") setHideRoutes(true);
        if (histo !== "/login") {
            history.push("/login");
        }
    };

    const setHdRoutes = val => {
        let expires = new Date();
        expires.setTime(expires.getTime() + 36969999 * 1000);
        setCookie("hideRoutes", val, { path: "/", expires });
        setHideRoutes(val);
    };

    // console.log(loggedUser);
    if (loggedUser === "") {
        setLogged(undefined);
    }

    React.useEffect(() => {
        checkCookies();
        document.onclick = function(event) {
            resetLogoutTimer();
        };
    }, []);

    return (
        <div>
            <AppBar
                userb={setUsr}
                toogleb={setHdRoutes}
                hideRoutes={hideRoutes}
                loggedUser={loggedUser}
            />
            <Sidebar
                hideRoutes={hideRoutes}
                userb={setUsr}
                toogleb={setHdRoutes}
                loggedUser={loggedUser}
            />
            <div>
                <LayoutContent
                    loggedUser={loggedUser}
                    userb={setUsr}
                    toogleb={setHdRoutes}
                />
            </div>
        </div>
    );
}

export default withStyles(styles)(LayoutSkeleton);
