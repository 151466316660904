import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./aneelmensal.css";
import { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import moment from "moment";
import RemoveIcon from "@material-ui/icons/Remove";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import crypto from "crypto";
import { useLocation } from "react-router-dom";
import history from "../../history";
import { R_ANEELDETALHE } from "../../constants/routes";
import {
    XAxis,
    CartesianGrid,
    YAxis,
    BarChart,
    Bar,
    Label,
    LabelList,
    ReferenceLine,
    Cell
} from "recharts";
import { useDownloadExcel } from "react-export-table-to-excel";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center",
    home: {
        textAlign: "center",
        marginLeft: "80px",
        marginTop: "20px"
    },
    Sum: {
        textAlign: "center",
        marginLeft: "80px"
    },
    linha: {
        paddingTop: "20px"
    }
});

function Aneel({ classes }) {
    var [date, setDate] = useState();
    var [dateFim, setDateFim] = useState();
    const [setInfosAnalitica, setInfosTabelaAnalitica] = useState([]);
    const [setInfosSumarizada, setInfosTabelaSumarizada] = useState([]);
    const [setInfosGrf, setInfosGrafico] = useState([]);
    var [empresaDados, setEmpresaDados] = useState([]);
    var [empresaLabel, setEmpresaLabel] = useState([]);
    var [infoTipicidade, setinfoTipicidade] = useState([]);
    var [diaBarra, setDiaBarra] = useState([]);
    const [exibirDadosTipicosmensal, setExibirDadosTipicosmensal] = useState(
        false
    );

    const axios = require("axios");

    async function getBearerToken() {
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

        const timestamp = Math.floor(Date.now() / 1000);
        const nonce = crypto.randomBytes(16).toString("hex");

        const body = new URLSearchParams();
        body.append("grant_type", "client_credentials");
        body.append("scope", "gestoronline");

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${Buffer.from(
                    `${clientId}:${clientSecret}`
                ).toString("base64")}`,
                "X-Timestamp": timestamp,
                "X-Nonce": nonce
            }
        };

        try {
            const response = await axios.post(
                "https://161.97.71.206/connect/token",
                body.toString(),
                config
            );
            return response.data.access_token;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async function getData() {
        const token = await getBearerToken();

        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalDiaDiaBaixaTensao?id_empresa=" +
                    empresaDados +
                    "&dt1=" +
                    date +
                    "&dt2=" +
                    dateFim +
                    "&tp=" +
                    infoTipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosTabelaAnalitica(response.data);
                console.log(response.data);
            });
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalGraficoDiaDiaBaixaTensao?id_empresa=" +
                    empresaDados +
                    "&dt1=" +
                    date +
                    "&dt2=" +
                    dateFim +
                    "&tp=" +
                    infoTipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosGrafico(response.data);
                console.log(response.data);
            });
    }

    const histo = history.location.state;

    if (histo) {
        var { empresaDados } = history.location.state;
    }

    if (infoTipicidade == "Geral") {
        var infoTipicidade = "Todos";
    }
    if (infoTipicidade == "") {
        var infoTipicidade = "Tipicidade";
    }

    const [count, setCount] = useState(0);
    const [shouldExecute, setShouldExecute] = useState(true);

    useEffect(() => {
        async function SearchData() {
            const token = await getBearerToken();
            setExibirDadosTipicosmensal(true);

            const histo = history.location.state;

            const Testevar = () => {
                if (histo) {
                    var { date, infoTipicidade } = history.location.state;
                    const firstDay = new Date(date);
                    firstDay.setDate(1);
                    const formattedDate = `${firstDay
                        .getFullYear()
                        .toString()}-${(firstDay.getMonth() + 1)
                        .toString()
                        .padStart(2, "0")}-${firstDay
                        .getDate()
                        .toString()
                        .padStart(2, "0")}`;
                    setDate(formattedDate);
                    setinfoTipicidade(infoTipicidade);

                    const lastDay = new Date(date);
                    lastDay.setMonth(lastDay.getMonth() + 1);
                    lastDay.setDate(0);
                    const formattedDateFim = `${lastDay.getFullYear()}-${(
                        lastDay.getMonth() + 1
                    )
                        .toString()
                        .padStart(2, "0")}-${lastDay
                        .getDate()
                        .toString()
                        .padStart(2, "0")}`;
                    setDateFim(formattedDateFim);
                }
            };
            Testevar();

            const execute = () => {
                if (diaBarra != "") {
                    history.push(R_ANEELDETALHE, {
                        date,
                        diaBarra,
                        empresaDados
                    });
                }
            };
            execute();
            if (shouldExecute) {
                setTimeout(() => {
                    setShouldExecute(false);
                    setCount(count + 1);
                }, 500);
            }

            axios
                .post(
                    "https://161.97.71.206/AneelRelatorioMensalDiaDiaBaixaTensao?id_empresa=" +
                        empresaDados +
                        "&dt1=" +
                        date +
                        "&dt2=" +
                        dateFim +
                        "&tp=" +
                        infoTipicidade,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
                .then(response => {
                    setInfosTabelaAnalitica(response.data);
                    console.log(response.data);
                });

            axios
                .post(
                    "https://161.97.71.206/AneelRelatorioMensalGraficoDiaDiaBaixaTensao?id_empresa=" +
                        empresaDados +
                        "&dt1=" +
                        date +
                        "&dt2=" +
                        dateFim +
                        "&tp=" +
                        infoTipicidade,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
                .then(response => {
                    setInfosGrafico(response.data);
                    console.log(response.data);
                });
            axios
                .post(
                    "https://161.97.71.206/AneelRelatorioMensalTotalizador?id_empresa=" +
                        empresaDados +
                        "&mes=11&ano=2022&tp=1&rs=2&tpse=1",
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
                .then(response => {
                    setInfosTabelaSumarizada(response.data);
                    console.log(response.data);
                });
        }
        if (empresaDados == "1") {
            setEmpresaLabel("Energisa Mato Grosso");
        }
        if (empresaDados == "2") {
            setEmpresaLabel("Energisa Tocantins");
        }
        if (empresaDados == "1002") {
            setEmpresaLabel("Energisa Mato Grosso do Sul");
        }
        if (empresaDados == "1008") {
            setEmpresaLabel("Energisa Paraíba");
        }
        if (empresaDados == "1009") {
            setEmpresaLabel("Energisa Borborema");
        }
        if (empresaDados == "1010") {
            setEmpresaLabel("Energisa Sergipe");
        }
        if (empresaDados == "1011") {
            setEmpresaLabel("Energisa Minas Gerais");
        }
        if (empresaDados == "1012") {
            setEmpresaLabel("Energisa Nova Friburgo");
        }
        if (empresaDados == "1013") {
            setEmpresaLabel("Energisa Sul Sudeste");
        }
        if (empresaDados == "1014") {
            setEmpresaLabel("Energisa Acre");
        }
        if (empresaDados == "1015") {
            setEmpresaLabel("Energisa Rondônia");
        }

        SearchData();
    }, [diaBarra, count, shouldExecute]);

    async function SearchData() {
        const token = await getBearerToken();
        setExibirDadosTipicosmensal(true);

        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalDiaDiaBaixaTensao?id_empresa=" +
                    empresaDados +
                    "&dt1=" +
                    date +
                    "&dt2=" +
                    dateFim +
                    "&tp=" +
                    infoTipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosTabelaAnalitica(response.data);
                console.log(response.data);
            });

        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalGraficoDiaDiaBaixaTensao?id_empresa=" +
                    empresaDados +
                    "&dt1=" +
                    date +
                    "&dt2=" +
                    dateFim +
                    "&tp=" +
                    infoTipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosGrafico(response.data);
                console.log(response.data);
            });
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalTotalizador?id_empresa=" +
                    empresaDados +
                    "&mes=11&ano=2022&tp=1&rs=2&tpse=1",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosTabelaSumarizada(response.data);
                console.log(response.data);
            });
    }

    const options = [
        { value: "baixatensao", label: "Baixa Tensão" },
        { value: "grandesclientes", label: "Grandes Clientes" },
        { value: "ouvidoria", label: "Ouvidoria" },
        { value: "procon", label: "Procon" }
    ];

    // const optionsDados = [
    //     { value: "Geral", label: "Gerais" },
    //     { value: "Típico", label: "Típicos" },
    //     { value: "Atípico", label: "Atípicos" },
    // ];

    const options2 = [
        { value: "1", label: "Energisa Mato Grosso" },
        { value: "2", label: "Energisa Tocantins" },
        { value: "1002", label: "Energisa Mato Grosso do Sul" },
        { value: "1008", label: "Energisa Paraíba" },
        { value: "1009", label: "Energisa Borborema" },
        { value: "1010", label: "Energisa Sergipe" },
        { value: "1011", label: "Energisa Minas Gerais" },
        { value: "1012", label: "Energisa Nova Friburgo" },
        { value: "1013", label: "Energisa Sul Sudeste" },
        { value: "1014", label: "Energisa Acre" },
        { value: "1015", label: "Energisa Rondônia" }
    ];

    const options5 = [
        { value: "Todos", label: "Todos" },
        { value: "Típico", label: "Típico" },
        { value: "Atípico", label: "Atípico" }
    ];

    var infodata = setInfosGrf.map(repo => {
        return repo;
    });

    if (setInfosGrf == "") {
        var infodata = [
            {
                Dia: "1"
            },
            {
                Dia: "2"
            },
            {
                Dia: "3"
            },
            {
                Dia: "4"
            },
            {
                Dia: "5"
            },
            {
                Dia: "6"
            },
            {
                Dia: "7"
            },
            {
                Dia: "8"
            },
            {
                Dia: "9"
            },
            {
                Dia: "10"
            },
            {
                Dia: "11"
            }
        ];
    }

    const dataTable = [
        {
            Dia: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">{moment(repo.DATA).format("D")}</p>
                );
            }),
            COF: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.COF.toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CO: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.CO}</p>;
            }),
            "Ch Ura": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Ch Ura"]
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca < 30s": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Ca < 30s"]
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca > 30s": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Ca > 30s"]
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CA: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.Ca.toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Cab < 30s": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Cab < 30s"]
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Cab > 30s": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Cab > 30s"]
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            Cab: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.Cab}</p>;
            }),
            "CR Rec": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["CR Rec"]
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "CR Plan": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["CR Plan"]
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),

            "IAB %": setInfosAnalitica.map(repo => {
                if (repo["IAB %"] > 4) {
                    return (
                        <p className="LinhaTst borderIabGreen">
                            {repo["IAB %"] + "%"}
                        </p>
                    );
                } else {
                    return (
                        <p className="LinhaTst borderIabRed">
                            {repo["IAB %"] + "%"}
                        </p>
                    );
                }
            }),
            "Cat/Rec %": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Cat/Rec %"].toFixed(2) + "%"}
                    </p>
                );
            }),
            TMA: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.TMA.toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            TMAb: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.TMAb.toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            TME: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.TME}</p>;
            }),
            "Agt.": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Agt."]
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            Limite: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.Limite.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            })
        }
    ];

    const dataTableSum = [
        {
            Tipo: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.tipo}</p>;
            }),
            COF: setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cof
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CO: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.co}</p>;
            }),
            "Ch Ura": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cura
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca < 30s": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat_ate_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca > 30s": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat_maior_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CA: setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Cab < 30s": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.cab_ate_30s}</p>;
            }),
            "Cab > 30s": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cab_maior_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            Cab: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.cab}</p>;
            }),
            "CR Rec": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cre
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "CR Plan": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.chpl
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "INS 30s": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.ins + "%"}</p>;
            }),
            "INS 60s": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.ins_60s + "%"}</p>;
            }),
            "IAB %": setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst borderIabGreen">{repo.iab + "%"}</p>
                );
            }),
            "ICO %": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.ico + "%"}</p>;
            }),
            "CR Prev % ": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.cre_plan + "%"}</p>;
            }),
            "Cat/Rec % ": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.cat_rec + "%"}</p>;
            }),
            TMA: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.tma}</p>;
            }),
            TMAb: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.tmab}</p>;
            }),
            TME: setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.tme}</p>;
            }),
            "Agt.": setInfosSumarizada.map(repo => {
                return <p className="LinhaTst">{repo.agentes}</p>;
            }),
            Limite: setInfosSumarizada.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.limite
                            .toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            })
        }
    ];

    const Head = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const Row = ({ record }) => {
        return (
            <tr className="testeTABE" key={record.id}>
                {keys.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    const HeadSum = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const RowSum = ({ record }) => {
        return (
            <tr className="testeTABE" key={record.id}>
                {keysSum.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "DadosAneelMensal" + date,
        sheet: "DadosAneelMensal" + date
    });

    const handleChange = selected => {
        console.log(selected);
        setEmpresaDados(selected.value);
        setEmpresaLabel(selected.label);
    };
    const handleDados = selected => {
        console.log(selected);
        setinfoTipicidade(selected.value);
    };

    const handleCellClick = entry => {
        setDiaBarra(entry.DATA);
        console.log(diaBarra);
    };

    const colors = ["#00cce2", "#ffc27d"];

    const keys = Object.keys(dataTable[0]);
    const keysSum = Object.keys(dataTableSum[0]);

    return (
        <body>
            <div className="fix-topsize">
                <div className="responsive-geral">
                    <div className="header-select">
                        <div className="header-select">
                            <div className="header-agregado">
                                <h3>Aneel</h3>
                                <p>Relatório Mensal</p>
                            </div>
                        </div>
                    </div>
                    {/* <hr></hr> */}

                    <div className="pesquisa-aneelanuagreg">
                        <div className="input-flexcolumn">
                            Painel
                            <Select
                                placeholder="Baixa Tensão"
                                options={options}
                            />
                        </div>
                        <div className="input-flexcolumn">
                            Empresa
                            <Select
                                placeholder={empresaLabel}
                                options={options2}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="input-flexcolumn">
                            Data Inicial
                            <input
                                value={date}
                                onChange={d => setDate(d.target.value)}
                                type="date"
                                className="input-date datePicker"
                            ></input>
                        </div>
                        <div className="input-flexcolumn">
                            Data Final
                            <input
                                value={dateFim}
                                onChange={s => setDateFim(s.target.value)}
                                type="date"
                                className="input-date datePicker"
                            ></input>
                        </div>
                        <div className="input-flexcolumn">
                            Tipicidade
                            <br />
                            <Select
                                placeholder={infoTipicidade}
                                value={infoTipicidade}
                                options={options5}
                                // isClearable={true}
                                onChange={handleDados}
                            />
                        </div>

                        <button onClick={SearchData} className="button-aneel">
                            CONSULTAR
                        </button>
                    </div>
                    <BarChart
                        width={1290}
                        height={220}
                        data={infodata}
                        barSize={23}
                        margin={{ bottom: 20, left: 20 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <ReferenceLine
                            strokeWidth={5}
                            y={85}
                            stroke="rgb(250, 209, 73)"
                            strokeDasharray="0"
                            isFront
                        />
                        <XAxis padding={{ bottom: 12 }} dataKey="DATA">
                            <Label
                                value="Dia"
                                offset={-15}
                                position="insideBottom"
                            />
                        </XAxis>

                        <YAxis padding={{ top: 18 }} />

                        <Bar
                            className="hover-bar"
                            isAnimationActive={false}
                            label={false}
                            dataKey="INS 30s"
                            fill="#00cce2"
                        >
                            <LabelList
                                dataKey="INS 30s"
                                fontSize={11.5}
                                position="top"
                                style={{ fill: "black" }}
                            />

                            {infodata.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={
                                        entry["INS 30s"] < 85
                                            ? colors[1]
                                            : colors[0]
                                    }
                                    onClick={() => handleCellClick(entry)}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                    <div className="sinopse-mensal">
                        <div className="header-flexiconmensal">
                            <div className="header-flex">
                                <RemoveIcon className="IconLine" />{" "}
                                <p className="DownLine">Limite INS 85%</p>
                            </div>
                            <div className="header-flex">
                                <FiberManualRecordIcon className="IconLine2" />
                                <p className="DownLine">Dia Típico</p>
                            </div>
                            <div className="header-flex">
                                <FiberManualRecordIcon className="IconLine3" />
                                <p className="DownLine">
                                    Dia Típico - INS Abaixo de 85%
                                </p>
                            </div>
                            <div className="header-flex">
                                <FiberManualRecordIcon className="IconLine4" />
                                <p className="DownLine">Dia Atípico</p>
                            </div>
                        </div>
                    </div>
                </div>
                {exibirDadosTipicosmensal && (
                    <div className="dados-tipicosmensal">
                        <div className="header-flexgap">
                            <p>{empresaLabel} -</p>
                            <p>{moment(date).format("DD/MM/YYYY")} </p> Até{" "}
                            <p>{moment(dateFim).format("DD/MM/YYYY")}</p>(
                            {infoTipicidade})
                        </div>
                    </div>
                )}
                <div className="table-mensal">
                    <table ref={tableRef} className={classes.home}>
                        <Head keys={keys} />
                        <tbody className="LinhaTst">
                            {dataTable.map(record => (
                                <Row record={record} />
                            ))}
                        </tbody>
                    </table>
                    <table className={classes.Sum}>
                        <HeadSum keys={keysSum} />
                        <tbody className="LinhaTst">
                            {dataTableSum.map(record => (
                                <RowSum record={record} />
                            ))}
                        </tbody>
                    </table>
                    <button className="button-excelexport" onClick={onDownload}>
                        Baixar dados da tabela em Excel
                    </button>
                </div>
            </div>
        </body>
    );
}
Aneel.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Aneel);
