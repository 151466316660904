// Libs
import { connect } from "react-redux";
import { push } from "connected-react-router";
// Component
import SidebarConsolidadoItem from "../../components/Sidebar/ConsolidadoItem";
// Constants
import { R_CUSTOCONSOLIDADO } from "../../constants/routes";
// Selectors
import { isItemSelected } from "../../redux/selectors/ui";
import history from "../../history";

const mapStateToProps = state => ({
    selected: isItemSelected(state)
});

const mapDispatchToProps = {
    onClick: () => history.push(R_CUSTOCONSOLIDADO)
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidebarConsolidadoItem);
