import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import "./1.css";
const styles = theme => ({
    iconSelected: {
        // color: theme.palette.primary.main
    }
});

const ListItem1 = ({ classes, icon, text, onClick, selected, ...props }) => (
    <ListItem className="teste-listitem" button onClick={onClick} {...props}>
        {/* <ListItemIcon className={selected ? classes.iconSelected : undefined}>
            {icon}
        </ListItemIcon> */}
        <ListItemText className="teste-listitem" primary={text} />
    </ListItem>
);

ListItem1.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

export default withStyles(styles)(ListItem1);
