import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import logo from "../Home/logo.png";
import "./styleLogin.css";
import ToastService from "../../services/ToastService";
import axios from "axios";
import logoener from "../Home/logoener2.png";

const styles = theme => ({
    login: {
        textAlign: "center"
    },
    pad: {
        marginLeft: "34%"
    },
    loginLogo: {
        height: "240px",
        width: "300px",
        marginTop: "-125px",
        marginLeft: "-40px"
    },
    loginHeader: {
        height: "100px"
        // padding: "10px"
    },
    loginTitle: {
        fontSize: "1.5em"
    },
    loginIntro: {
        fontSize: "large"
    }
});

class Login extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        hideRoutes: PropTypes.bool,
        tooglea: PropTypes.any,
        stUs: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            infoLogin: "",
            result: "",
            errorMessage: "",
            loading: false
        };
    }

    componentDidMount() {
        // this.props.tooglea(false);
        // console.log("Login Mounted");
        // console.log(this.props.tooglea);
    }

    // async getBearerToken() {
    //     const clientId = process.env.REACT_APP_CLIENT_ID;
    //     const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

    //     const timestamp = Math.floor(Date.now() / 1000);
    //     const nonce = crypto.randomBytes(16).toString("hex");

    //     const body = new URLSearchParams();
    //     body.append("grant_type", "client_credentials");
    //     body.append("scope", "gestoronline");

    //     const config = {
    //         headers: {
    //             "Content-Type": "application/x-www-form-urlencoded",
    //             Authorization: `Basic ${Buffer.from(
    //                 `${clientId}:${clientSecret}`
    //             ).toString("base64")}`,
    //             "X-Timestamp": timestamp,
    //             "X-Nonce": nonce
    //         }
    //     };

    //     try {
    //         const response = await axios.post(
    //             "https://161.97.71.206/connect/token",
    //             body.toString(),
    //             config
    //         );
    //         return response.data.access_token;
    //     } catch (error) {
    //         console.error(error);
    //         return null;
    //     }
    // }

    useEffect = () => {
        // const token = this.getBearerToken();
        axios
            .post(
                "https://161.97.71.206/ConsultaUsuario?u=" +
                    this.state.email +
                    "&s=" +
                    this.state.password,
                {}
                // {
                //     headers: {
                //         Authorization: `Bearer ${token}`
                //     }
                // }
            )
            .then(response => {
                this.state.infoLogin = response.data;
                console.log(response.data);
            });
    };
    ExecutarLogin = e => {
        // this.props.toogle(false);
        console.log(this.props.hideRoutes);
        // this.props.tooglea(!this.props.hideRoutes);
        this.tryLogin();
        e.preventDefault();

        // this.state.infoLogin.map(repo => {
        //     if (repo.ATIVO >= 1) {
        //         console.log("login funcional");
        //     }
        // });
    };

    async tryLogin() {
        this.setState({ loading: true });
        this.setState({ errorMessage: "" });
        // const token = await this.getBearerToken();
        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url:
                "https://161.97.71.206/ConsultaUsuario?u=" +
                this.state.email +
                "&s=" +
                this.state.password,
            headers: {}
        };

        axios
            .request(config)
            .then(response => {
                if (response.data == undefined) {
                    this.setState({ loading: false });
                    ToastService.warningLight("Falha no login");

                    this.setState({ errorMessage: "Falha no login" });
                    return false;
                }
                if (response.data !== "0") {
                    this.setState({
                        infoLogin: response.data[0],
                        loading: false
                    });
                    // console.log("teste", response.data);
                    this.props.stUs(this.state.infoLogin);
                    this.props.tooglea(false);
                    // console.log("emitindo para exibir menu lateral");
                } else {
                    ToastService.warningLight("Dados inválidos");

                    this.setState({
                        errorMessage: "Dados inválidos",
                        loading: false
                    });
                }
            })
            .catch(error => {
                // console.log(error);
                ToastService.warningLight("Falha no login");
            });

        axios
            .post(
                "https://161.97.71.206/ConsultaUsuario?u=" +
                    this.state.email +
                    "&s=" +
                    this.state.password,
                {}
            )
            .catch(e => {
                console.log(e);
                this.setState({ loading: false });
                // this.setState({ errorMessage: e });
            })
            .then(response => {});
    }
    setEmail(val) {
        this.setState({ email: val });
    }
    setPassword(val) {
        this.setState({ password: val });
    }
    setError(message) {
        this.setState({ errorMessage: message });
    }

    render() {
        return (
            <div className={this.props.classes.login}>
                <header>
                    <div className="appbar-titleLogin">
                        <img
                            className="logo-expandHeader"
                            alt="logo"
                            src={logoener}
                        ></img>
                    </div>
                </header>
                <div className="container">
                    <div className="container-login">
                        <div className="wrap-login">
                            <div className={this.props.classes.loginHeader}>
                                <img
                                    src={logo}
                                    className={this.props.classes.loginLogo}
                                    alt="logo"
                                />
                            </div>
                            <div className="title-gestoronline">
                                Gestor Online
                            </div>

                            {/* <span className="login-form-title">Bem Vindo</span> */}

                            <span className="login-form-title">
                                <div className="wrap-input">
                                    {/* {error} */}
                                    <label>
                                        <strong>Usuário</strong>
                                    </label>
                                    <input
                                        className={
                                            this.state.email !== ""
                                                ? "has-val input"
                                                : "input"
                                        }
                                        value={this.state.email}
                                        onChange={e =>
                                            this.setEmail(e.target.value)
                                        }
                                    />
                                </div>

                                <div className="wrap-input">
                                    <label>
                                        <strong>Senha</strong>
                                    </label>
                                    <input
                                        type="password"
                                        className={
                                            this.state.password !== ""
                                                ? "has-val input"
                                                : "input"
                                        }
                                        value={this.state.password}
                                        onChange={e =>
                                            this.setPassword(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="container-login-form-btn">
                                    {this.state.loading ? (
                                        <div className="loader"></div>
                                    ) : null}
                                    {this.state.errorMessage ? (
                                        <div className="error-message">
                                            {this.state.errorMessage}
                                        </div>
                                    ) : null}
                                    <button
                                        onClick={e => this.ExecutarLogin(e)}
                                        className="login-form-btn"
                                    >
                                        ACESSAR
                                    </button>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <footer className="footer-login">
                    Wiser Tecnologia <br /> {new Date().getFullYear()}
                </footer>
            </div>
        );
    }
}

export default withStyles(styles)(Login);
