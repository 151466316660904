import toast from "react-hot-toast";

const ToastService = {
    default: function(msg) {
        toast.success(msg, {
            style: {
                border: "1px solid #713200",
                padding: "16px",
                color: "#713200"
            },
            iconTheme: {
                primary: "#713200",
                secondary: "#FFFAEE"
            }
        });
    },
    success: function(msg) {
        toast.success(msg, {});
    },
    warningLight: function(msg) {
        toast.error(msg, {
            style: {
                border: "4.5px solid #F56E1D",
                backgroundColor: "#029FC5",
                padding: "16px",
                color: "white"
            }
        });
    }
};

export default ToastService;
