import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { useState } from "react";
import Select from "react-select";
import { saveAs } from "file-saver";
import { useDownloadExcel } from "react-export-table-to-excel";
import crypto from "crypto";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center",
    home: {
        textAlign: "center",
        marginLeft: "80px",
        marginTop: "20px"
    },
    Sum: {
        textAlign: "center",
        marginLeft: "80px"
    },
    linha: {
        paddingTop: "20px"
    }
});

function Aneel({ classes }) {
    const [setInfosAnalitica, setInfosTabelaAnalitica] = useState([]);
    var [empresaDados, setEmpresaDados] = useState([]);
    var [mes, setInfoMes] = useState([]);
    var [mesLabel, setInfoMesLabel] = useState([]);
    var [ano, setInfoAno] = useState([]);
    var [anoLabel, setInfoAnoLabel] = useState([]);
    var [tipicidade, setInfoTipicidade] = useState([]);
    var [tipicidadeLabel, setInfoTipicidadeLabel] = useState([]);
    var [empresaLabel, setEmpresaLabel] = useState([]);
    var [empresaSigla, setEmpresaSigla] = useState([]);
    var [infoTipicidade, setinfoTipicidade] = useState([]);
    const [exibirDados, setExibirDados] = useState(false);
    // const [token, setToken] = useState([]);

    const axios = require("axios");

    async function getBearerToken() {
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

        const timestamp = Math.floor(Date.now() / 1000);
        const nonce = crypto.randomBytes(16).toString("hex");

        const body = new URLSearchParams();
        body.append("grant_type", "client_credentials");
        body.append("scope", "gestoronline");

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${Buffer.from(
                    `${clientId}:${clientSecret}`
                ).toString("base64")}`,
                "X-Timestamp": timestamp,
                "X-Nonce": nonce
            }
        };

        try {
            const response = await axios.post(
                "https://161.97.71.206/connect/token",
                body.toString(),
                config
            );
            return response.data.access_token;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    async function SearchData() {
        const token = await getBearerToken();
        setExibirDados(true);
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioMensalAgregado?id_empresa=" +
                    empresaDados +
                    "&ano=" +
                    ano +
                    "&mes=" +
                    mes +
                    "&tp=" +
                    tipicidade,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                console.log(response.data);
                setInfosTabelaAnalitica(response.data);
            });
    }

    const options = [
        { value: "baixatensao", label: "Baixa Tensão" },
        { value: "grandesclientes", label: "Grandes Clientes" },
        { value: "ouvidoria", label: "Ouvidoria" },
        { value: "procon", label: "Procon" }
    ];

    // const optionsDados = [
    //     { value: "Geral", label: "Gerais" },
    //     { value: "Típico", label: "Típicos" },
    //     { value: "Atípico", label: "Atípicos" },
    // ];

    const options2 = [
        { value: "1", label: "Energisa Mato Grosso", sigla: "EMT" },
        { value: "2", label: "Energisa Tocantins", sigla: "ETO" },
        { value: "1002", label: "Energisa Mato Grosso do Sul", sigla: "EMS" },
        { value: "1008", label: "Energisa Paraíba", sigla: "EPB" },
        { value: "1009", label: "Energisa Borborema", sigla: "EBO" },
        { value: "1010", label: "Energisa Sergipe", sigla: "ESE" },
        { value: "1011", label: "Energisa Minas Gerais", sigla: "EMG" },
        { value: "1012", label: "Energisa Nova Friburgo", sigla: "ENF" },
        { value: "1013", label: "Energisa Sul Sudeste", sigla: "ESS" },
        { value: "1014", label: "Energisa Acre", sigla: "EAC" },
        { value: "1015", label: "Energisa Rondônia", sigla: "ERO" }
    ];

    const options4 = [
        { value: "1", label: "Janeiro" },
        { value: "2", label: "Fevereiro" },
        { value: "3", label: "Março" },
        { value: "4", label: "Abril" },
        { value: "5", label: "Maio" },
        { value: "6", label: "Junho" },
        { value: "7", label: "Julho" },
        { value: "8", label: "Agosto" },
        { value: "9", label: "Setembro" },
        { value: "10", label: "Outubro" },
        { value: "11", label: "Novembro" },
        { value: "12", label: "Dezembro" }
    ];

    const options5 = [
        { value: "2021", label: "2021" },
        { value: "2022", label: "2022" },
        { value: "2023", label: "2023" }
    ];
    const options6 = [
        { value: "0", label: "Geral" },
        { value: "1", label: "Típicos" }
    ];

    const dataTable = [
        {
            Empresa: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{empresaSigla}</p>;
            }),
            Tipo: <p className="LinhaTst">Operação</p>,

            CO: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.co}</p>;
            }),
            "Ch Ura": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cura
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca < 30s": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat_ate_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Ca > 30s": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat_maior_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            CA: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cat
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "Cab < 30s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.cab_ate_30s}</p>;
            }),
            "Cab > 30s": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cab_maior_30s
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            Cab: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.cab}</p>;
            }),
            "CR Rec": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.cre
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "CR Plan": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.chpl
                            .toFixed(0)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            }),
            "INS 30s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.ins + "%"}</p>;
            }),
            "INS 60s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.ins_60s + "%"}</p>;
            }),
            "IAB %": setInfosAnalitica.map(repo => {
                if (repo.iab > 4) {
                    return (
                        <p className="LinhaTst borderIabGreen">
                            {repo.iab + "%"}
                        </p>
                    );
                } else {
                    return (
                        <p className="LinhaTst borderIabRed">
                            {repo.iab + "%"}
                        </p>
                    );
                }
            }),
            "ICO %": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.ico + "%"}</p>;
            }),
            "CR Prev % ": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.cre_plan + "%"}</p>;
            }),
            "Cat/Rec % ": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.cat_rec + "%"}</p>;
            }),
            TMA: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.tma.toFixed(2)}</p>;
            }),
            TMAb: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.tmab}</p>;
            }),
            TME: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.tme}</p>;
            }),
            "Agt.": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.agentes}</p>;
            }),
            Limite: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.limite
                            .toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            })
        }
    ];

    const Head = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const Row = ({ record }) => {
        return (
            <tr className="testeTABE" key={record.id}>
                {keys.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    // const tableRef = useRef(null);

    // const { onDownload } = useDownloadExcel({
    //     currentTableRef: tableRef.current,
    //     filename: "DadosAneelMensalAgreg" + mes + ano,
    //     sheet: "DadosAneelMensalAgreg" + mes + ano
    // });

    const tableRef = useRef(null);

    const exportToExcel = () => {
        const tableHtml = tableRef.current.outerHTML;

        const blob = new Blob([tableHtml], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
        });
        saveAs(blob, "TableMensalAgregado.xls");
    };

    const handleEmpresa = selected => {
        console.log(selected);
        setEmpresaDados(selected.value);
        setEmpresaLabel(selected.label);
        setEmpresaSigla(selected.sigla);
    };
    const handleAno = selected => {
        console.log(selected);
        setInfoAno(selected.value);
        setInfoAnoLabel(selected.label);
    };
    const handleMes = selected => {
        console.log(selected);
        setInfoMes(selected.value);
        setInfoMesLabel(selected.label);
    };

    const handleTipicidade = selected => {
        console.log(selected);
        setInfoTipicidade(selected.value);
        setInfoTipicidadeLabel(selected.label);
    };

    const keys = Object.keys(dataTable[0]);
    return (
        <body>
            <div className="responsive-geral">
                <div className="header-select">
                    <div className="header-select">
                        <div className="header-agregado">
                            <h3>Aneel</h3>
                            <p>Relatório Mensal Agregado</p>
                        </div>
                    </div>
                </div>
                {/* <hr></hr> */}

                <div className="pesquisa-aneelanuagreg">
                    <div className="input-flexcolumn">
                        Painel
                        <Select placeholder="Baixa Tensão" options={options} />
                    </div>
                    <div className="input-flexcolumn">
                        Empresa
                        <Select
                            placeholder="Selecionar"
                            options={options2}
                            onChange={handleEmpresa}
                        />
                    </div>

                    <div className="input-flexcolumn">
                        Mês
                        <br />
                        <Select
                            placeholder="Selecionar"
                            options={options4}
                            // isClearable={true}
                            onChange={handleMes}
                        />
                    </div>
                    <div className="input-flexcolumn">
                        Ano
                        <br />
                        <Select
                            placeholder="Selecionar"
                            options={options5}
                            // isClearable={true}
                            onChange={handleAno}
                        />
                    </div>
                    <div className="input-flexcolumn">
                        Tipicidade
                        <br />
                        <Select
                            placeholder="Selecionar"
                            options={options6}
                            // isClearable={true}
                            onChange={handleTipicidade}
                        />
                    </div>

                    <button onClick={SearchData} className="button-aneel">
                        CONSULTAR
                    </button>
                </div>
            </div>
            <div className="dados-tipicosmensal">
                {/* <div className="header-flexgap">
                    <p>{empresaLabel} -</p>
                    <p>{moment(date).format("DD/MM/YYYY")} </p> Até{" "}
                    <p>{moment(dateFim).format("DD/MM/YYYY")}</p>(Dados Típicos)
                </div> */}
            </div>
            {exibirDados && (
                <div className="dados-tipicosmensal">
                    {empresaLabel} / {mesLabel} - {anoLabel} ({tipicidadeLabel})
                </div>
            )}
            <div className="table-mensal">
                <table ref={tableRef} className={classes.home}>
                    <Head keys={keys} />
                    <tbody className="LinhaTst">
                        {dataTable.map(record => (
                            <Row record={record} />
                        ))}
                    </tbody>
                </table>

                <button className="button-excelexport" onClick={exportToExcel}>
                    Baixar dados da tabela em Excel
                </button>
            </div>
        </body>
    );
}
Aneel.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Aneel);
