import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./aneeldetalhe.css";
import { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import moment from "moment";
import RemoveIcon from "@material-ui/icons/Remove";
import crypto from "crypto";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
    XAxis,
    CartesianGrid,
    YAxis,
    BarChart,
    Bar,
    Label,
    LabelList
} from "recharts";
import history from "../../history";
import { useDownloadExcel } from "react-export-table-to-excel";

const styles = theme => ({
    fontFamily: "sans-serif",
    textAlign: "center",
    home: {
        textAlign: "center",
        marginLeft: "80px",
        marginTop: "20px"
    },
    Sum: {
        textAlign: "center",
        marginLeft: "80px"
    },
    linha: {
        paddingTop: "20px"
    }
});

function Aneel({ classes }) {
    var [date, setDate] = useState();
    // var [date, setDateFim] = useState();
    const [setInfosAnalitica, setInfosTabelaAnalitica] = useState([]);
    const [curr, setCurr] = useState([]);
    var [tipoGrf, setinfosTipoGrf] = useState([2]);
    const [setInfosGrf, setInfosGrafico] = useState([]);
    var [empresaLabel, setEmpresaLabel] = useState([]);
    var [empresaDados, setEmpresaDados] = useState([]);
    var [diaBarra, setDiaBarra] = useState([]);
    const [exibirDados, setExibirDados] = useState(false);

    const axios = require("axios");

    async function getBearerToken() {
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

        const timestamp = Math.floor(Date.now() / 1000);
        const nonce = crypto.randomBytes(16).toString("hex");

        const body = new URLSearchParams();
        body.append("grant_type", "client_credentials");
        body.append("scope", "gestoronline");

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${Buffer.from(
                    `${clientId}:${clientSecret}`
                ).toString("base64")}`,
                "X-Timestamp": timestamp,
                "X-Nonce": nonce
            }
        };

        try {
            const response = await axios.post(
                "https://161.97.71.206/connect/token",
                body.toString(),
                config
            );
            return response.data.access_token;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    useEffect(() => {
        const dataNow = new Date();
        const dia = String(dataNow.getDate()).padStart(2, "0");
        const mes = String(dataNow.getMonth() + 1).padStart(2, "0");
        const ano = dataNow.getFullYear();
        const dataAtual = ano + "-" + mes + "-" + dia;
        // setCurr(dataAtual);
        const token = getBearerToken();

        const histo = history.location.state;

        if (histo) {
            var { date, diaBarra, empresaDados } = history.location.state;
            const firstDay = new Date(date);
            firstDay.setDate(diaBarra);
            const formattedDate = `${firstDay.getFullYear().toString()}-${(
                firstDay.getMonth() + 2
            )
                .toString()
                .padStart(2, "0")}-${firstDay
                .getDate()
                .toString()
                .padStart(2, "0")}`;
            setDate(formattedDate);
            setEmpresaDados(empresaDados);
            setinfosTipoGrf("2");
        }

        async function SearchData() {
            const token = await getBearerToken();
            setExibirDados(true);
            axios
                .post(
                    "https://161.97.71.206/AneelRelatorioDiario30em30Grafico?id_empresa=" +
                        empresaDados +
                        "&dt1=" +
                        date +
                        "&dt2=" +
                        date +
                        "&tpg=" +
                        tipoGrf,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
                .then(response => {
                    setInfosGrafico(response.data);
                    console.log(response.data);
                });
            axios
                .post(
                    "https://161.97.71.206/AneelRelatorioDiario30em30?id_empresa=" +
                        empresaDados +
                        "&dt1=" +
                        date +
                        "&dt2=" +
                        date,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
                .then(response => {
                    setInfosTabelaAnalitica(response.data);
                    console.log(response.data);
                });
        }
        if (empresaDados == "1") {
            setEmpresaLabel("Energisa Mato Grosso -");
        }
        if (empresaDados == "2") {
            setEmpresaLabel("Energisa Tocantins -");
        }
        if (empresaDados == "1002") {
            setEmpresaLabel("Energisa Mato Grosso do Sul -");
        }
        if (empresaDados == "1008") {
            setEmpresaLabel("Energisa Paraíba -");
        }
        if (empresaDados == "1009") {
            setEmpresaLabel("Energisa Borborema -");
        }
        if (empresaDados == "1010") {
            setEmpresaLabel("Energisa Sergipe -");
        }
        if (empresaDados == "1011") {
            setEmpresaLabel("Energisa Minas Gerais -");
        }
        if (empresaDados == "1012") {
            setEmpresaLabel("Energisa Nova Friburgo -");
        }
        if (empresaDados == "1013") {
            setEmpresaLabel("Energisa Sul Sudeste -");
        }
        if (empresaDados == "1014") {
            setEmpresaLabel("Energisa Acre -");
        }
        if (empresaDados == "1015") {
            setEmpresaLabel("Energisa Rondônia -");
        }
        SearchData();
    }, [curr]);

    async function SearchData() {
        const token = await getBearerToken();
        setExibirDados(true);
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioDiario30em30Grafico?id_empresa=" +
                    empresaDados +
                    "&dt1=" +
                    date +
                    "&dt2=" +
                    date +
                    "&tpg=" +
                    tipoGrf,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosGrafico(response.data);
                console.log(response.data);
            });
        axios
            .post(
                "https://161.97.71.206/AneelRelatorioDiario30em30?id_empresa=" +
                    empresaDados +
                    "&dt1=" +
                    date +
                    "&dt2=" +
                    date,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            .then(response => {
                setInfosTabelaAnalitica(response.data);
                console.log(response.data);
            });
    }

    const options = [
        { value: "1", label: "Chamadas Atendidas" },
        { value: "2", label: "Chamadas Atendidas X Recebidas X Planejadas" },
        { value: "3", label: "TMA Plan X TMA Realizado" }
    ];

    var infodata = setInfosGrf.map(repo => {
        return repo;
    });

    if (setInfosGrf == "") {
        var infodata = [
            {
                Dia: "1"
            },
            {
                Dia: "2"
            },
            {
                Dia: "3"
            },
            {
                Dia: "4"
            },
            {
                Dia: "5"
            },
            {
                Dia: "6"
            },
            {
                Dia: "7"
            },
            {
                Dia: "8"
            },
            {
                Dia: "9"
            },
            {
                Dia: "10"
            },
            {
                Dia: "11"
            }
        ];
    }

    const dataTable = [
        {
            Intervalo: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.Intervalo}</p>;
            }),
            COF: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.COF}</p>;
            }),
            CO: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.CO}</p>;
            }),
            "Ch Ura": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["Ch Ura"]}</p>;
            }),
            "Ca < 30s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["Ca < 30s"]}</p>;
            }),
            "Ca > 30s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["Ca > 30s"]}</p>;
            }),
            CA: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.Ca}</p>;
            }),
            "Cab < 30s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["Cab < 30s"]}</p>;
            }),
            "Cab > 30s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["Cab > 30s"]}</p>;
            }),
            Cab: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.Cab}</p>;
            }),
            "CR Rec": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["CR Rec"]}</p>;
            }),
            "CR Plan": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["CR Plan"]}</p>;
            }),
            "INS 30s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["CR Plan"]}</p>;
            }),
            "INS 60s": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["CR Plan"]}</p>;
            }),

            "IAB %": setInfosAnalitica.map(repo => {
                if (repo["IAB %"] > 4) {
                    return (
                        <p className="LinhaTst borderIabGreen">
                            {repo["IAB %"] + "%"}
                        </p>
                    );
                } else {
                    return (
                        <p className="LinhaTst borderIabRed">
                            {repo["IAB %"] + "%"}
                        </p>
                    );
                }
            }),
            "ICO %": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">0</p>;
            }),
            "CR Prev %": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">0</p>;
            }),
            "Cat/Rec %": setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo["Cat/Rec %"].toFixed(2) + "%"}
                    </p>
                );
            }),
            TMA: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.TMA}</p>;
            }),
            TMAb: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.TMAb}</p>;
            }),
            TME: setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo.TME}</p>;
            }),
            "Agt.": setInfosAnalitica.map(repo => {
                return <p className="LinhaTst">{repo["Agt."]}</p>;
            }),
            Limite: setInfosAnalitica.map(repo => {
                return (
                    <p className="LinhaTst">
                        {repo.Limite.toFixed(2)
                            .replace(".", ",")
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                    </p>
                );
            })
        }
    ];

    const Head = ({ keys }) => {
        return (
            <thead>
                <tr>
                    {keys.map(key => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
        );
    };
    const Row = ({ record }) => {
        return (
            <tr className="testeTABE" key={record.id}>
                {keys.map(key => (
                    <td key={key}> {record[key]}</td>
                ))}
            </tr>
        );
    };

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "DadosAneelMensal" + date,
        sheet: "DadosAneelMensal" + date
    });

    const handleTipoGrf = selected => {
        console.log(selected);
        setinfosTipoGrf(selected.value);
    };

    const keys = Object.keys(dataTable[0]);

    return (
        <body>
            <div className="responsive-geral">
                <div className="header-selectdetalhe">
                    <div className="header-select">
                        <div className="header-agregado">
                            <h3>Aneel</h3>
                            <p>Relatório Detalhe Diário</p>
                        </div>
                        <Select
                            className="selectreactdetalhe"
                            placeholder="Gráficos"
                            options={options}
                            // isClearable={true}
                            onChange={handleTipoGrf}
                        />
                    </div>
                </div>
                {/* <hr></hr> */}

                <BarChart
                    width={1320}
                    height={290}
                    data={infodata}
                    barSize={18}
                    margin={{ bottom: 20, left: 20, top: 100 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    {/* <ReferenceLine
                        strokeWidth={5}
                        y={85}
                        stroke="rgb(250, 209, 73)"
                        strokeDasharray="0"
                        isFront
                    /> */}
                    <XAxis padding={{ bottom: 12 }} dataKey="Intervalo">
                        <Label
                            value="Intervalo"
                            offset={-15}
                            position="insideBottom"
                        />
                    </XAxis>

                    <YAxis scale="auto" padding={{ top: 18 }} />

                    <Bar
                        isAnimationActive={false}
                        label={false}
                        dataKey="INS 85-30"
                        fill="#00cce2"
                        margin={{ left: 20 }}
                    >
                        <LabelList
                            className="LabelListMod"
                            dataKey="INS 85-30"
                            fontSize={10.5}
                            position="top"
                            angle="-45"
                            offset={12}
                        />

                        {/* {data.map((entry, index) => (

                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))} */}
                    </Bar>
                    <Bar
                        isAnimationActive={false}
                        label={false}
                        dataKey="Limite"
                        fill="#00cce2"
                    >
                        <LabelList
                            className="LabelListMod"
                            dataKey="Limite"
                            fontSize={10.5}
                            position="top"
                            angle="-45"
                            offset={12}
                        />

                        {/* {data.map((entry, index) => (

                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))} */}
                    </Bar>
                    <Bar
                        isAnimationActive={false}
                        label={false}
                        dataKey="TMA"
                        fill="#00cce2"
                    >
                        <LabelList
                            className="LabelListMod"
                            dataKey="TMA"
                            fontSize={10.5}
                            position="top"
                            angle="-45"
                            offset={12}
                        />

                        {/* {data.map((entry, index) => (

                                <Cell key={`cell-${index}`} fill={colors[index]} />
                            ))} */}
                    </Bar>
                </BarChart>

                <div className="sinopse-mensal">
                    <div className="header-flexiconmensal">
                        <div className="header-flex">
                            <RemoveIcon className="IconLine" />{" "}
                            <p className="DownLine">Limite INS 85%</p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine2" />
                            <p className="DownLine">Dia Típico</p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine3" />
                            <p className="DownLine">
                                Dia Típico - INS Abaixo de 85%
                            </p>
                        </div>
                        <div className="header-flex">
                            <FiberManualRecordIcon className="IconLine4" />
                            <p className="DownLine">Dia Atípico</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pesquisa-aneeldetalhe">
                <div className="input-flexcolumn">
                    Data Inicial
                    <input
                        value={date}
                        onChange={d => setDate(d.target.value)}
                        type="date"
                        className="input-date datePicker"
                    ></input>
                </div>
                <div hidden className="input-flexcolumn">
                    {/* Data Final */}
                    <input
                        value={date}
                        onChange={s => setDate(s.target.value)}
                        type="date"
                        className="input-date datePicker"
                        hidden
                    ></input>
                </div>

                <button onClick={SearchData} className="button-aneel">
                    CONSULTAR
                </button>
            </div>
            {exibirDados && (
                <div className="dados-tipicosmensal">
                    <div className="header-flexgap">
                        <p>{empresaLabel}</p>
                        <p>{moment(date).format("DD/MM/YYYY")} </p> Até{" "}
                        <p>{moment(date).format("DD/MM/YYYY")}</p>(Dados
                        Típicos)
                    </div>
                </div>
            )}
            <div className="table-mensal">
                <table ref={tableRef} className={classes.home}>
                    <Head keys={keys} />
                    <tbody className="LinhaTst">
                        {dataTable.map(record => (
                            <Row record={record} />
                        ))}
                    </tbody>
                </table>
            </div>
        </body>
    );
}
Aneel.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Aneel);
