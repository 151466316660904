import React, { Component } from "react";
import { Route, Switch, Router, Redirect } from "react-router";
import PropTypes from "prop-types";
import history from "../../history";

import Login from "../../components/Login/Login";
import Home from "../../components/Home/Home";
import BaixaTensao from "../../components/BaixaTensao/BaixaTensao";
import GrandesClientes from "../../components/GrandesClientes/GrandesClientes";
import NoWhere from "../../components/NoWhere/NoWhere";
import Ouvidoria from "../../components/Ouvidoria/Ouvidoria";
import Procon from "../../components/Procon/Procon";
import Aneel from "../../components/Aneel/Aneel";
import BSC from "../../components/BSC/bsc";
import AneelMensal from "../../components/AneelMensal/AneelMensal";
import AneelAgregado from "../../components/AneelAgregado/AneelAgregado";
import AneelAnual from "../../components/AneelAnual/AneelAnual";
import AneelAnualAgregado from "../../components/AneelAnualAgregado/AneelAnualAgregado";
import AneelQualidade from "../../components/AneelQualidade/AneelQualidade";
import AneelAtendimento from "../../components/AneelAtendimento/AneelAtendimento";
import CCV from "../../components/CCV/CCV";
import BSCIAB from "../../components/BSCIAB/bsciab";
import BSCICO from "../../components/BSCICO/bscico";
import BSCINS from "../../components/BSCINS/bscins";
import BSCTMA from "../../components/BSCTMA/bsctma";
import ccvanual from "../../components/CCVMensal/ccvanual";
import custotelefonia from "../../components/CustoTelefonia/custotelefonia";
import CustoDemais from "../../components/CustoDemais/CustoDemais";
import Consolidado from "../../components/Consolidado/Consolidado";
import BSCUra from "../../components/BSCUra/BSCUra";
import BSCAtendidas from "../../components/BSCAtendidas/BSCAtendidas";
import BSCMinutagem from "../../components/BSCMinutagem/BSCMinutagem";
import BSCRecebidas from "../../components/BSCRecebidas/BSCRecebidas";
import BSCRETURA from "../../components/BSCRETURA/BSCRETURA";
import BSCRETNUM from "../../components/BSCRETNUM/BSCRETNUM";
import OrcamentoMensal from "../../components/OrcamentoMensal/OrcamentoMensal";
import OrcamentoAnual from "../../components/OrcamentoAnual/OrcamentoAnual";
import AneelDetalheDiario from "../../components/AneelDetalheDiario/AneelDetalheDiario";
import RedefinirSenha from "../../components/RedefinirSenha/RedefinirSenha";

import {
    HOME,
    LOGIN,
    PBTENSAO,
    P_G_CLIENTE,
    P_G_OUVIDORIA,
    P_PROCON,
    R_ANEEL,
    R_BSCCHAMADAS,
    R_ANEELMENSAL,
    R_ANEELAGREGADO,
    R_ANEELANUAL,
    R_ANEELANUALAGREG,
    R_ANEELQUALIDADE,
    R_ANEELATENDIMENTO,
    R_CCVMENSAL,
    R_BSCIAB,
    R_BSCINS,
    R_BSCICO,
    R_BSCTMA,
    R_CCVANUAL,
    R_CUSTOTELEFONIA,
    R_CUSTODEMAIS,
    R_CUSTOCONSOLIDADO,
    R_BSCURA,
    R_BSCATENDIDAS,
    R_BSCMINUTAGEM,
    R_BSCRECEBIDAS,
    R_BSCRETURA,
    R_BSCRETNUM,
    R_ORCAMENTOMENSAL,
    R_ORCAMENTOANUAL,
    R_ANEELDETALHE,
    ADM_RESET_PASS,
    ADM_TIPOUSUARIO
} from "../../constants/routes";
import AlterarTipoUsuario from "../../components/AlterarTipoUsuario/AlterarTipoUsuario";

// const LayoutContent = () =>
class LayoutContent extends Component {
    static propTypes = {
        classes: PropTypes.object,
        toogleb: PropTypes.any,
        userb: PropTypes.any,
        loggedUser: PropTypes.any,
        hideRoutes: PropTypes.bool
    };
    constructor(props) {
        super(props);

        this.state = {
            hideRoutes: true,
            loggedUser: {}
        };
        this.toogleMenua = this.toogleMenua.bind(this);
        this.setUser = this.setUser.bind(this);
    }
    toogleMenua(val) {
        this.props.toogleb(val);
    }
    setUser(user) {
        this.props.userb(user);
        history.push("/painel/baixaTensao");
    }

    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route
                        path="/"
                        exact
                        component={() => (
                            <Redirect
                                to={LOGIN}
                                hideRoutes={this.state.hideRoutes}
                                tooglea={this.toogleMenua}
                                stUs={this.setUser}
                            />
                        )}
                    />
                    <Route
                        path={LOGIN}
                        exact
                        component={() => (
                            <Login
                                hideRoutes={this.state.hideRoutes}
                                tooglea={this.toogleMenua}
                                stUs={this.setUser}
                            />
                        )}
                    />
                    <Route
                        path={HOME}
                        exact
                        component={() => (
                            <Home loggedUser={this.props.loggedUser} />
                        )}
                    />
                    <Route path={PBTENSAO} exact component={BaixaTensao} />
                    <Route
                        path={P_G_CLIENTE}
                        exact
                        component={GrandesClientes}
                    />
                    <Route path={P_G_OUVIDORIA} exact component={Ouvidoria} />
                    <Route path={P_PROCON} exact component={Procon} />
                    <Route path={R_ANEEL} exact component={Aneel} />
                    <Route path={R_BSCCHAMADAS} exact component={BSC} />
                    <Route path={R_ANEELMENSAL} exact component={AneelMensal} />
                    <Route
                        path={R_ANEELAGREGADO}
                        exact
                        component={AneelAgregado}
                    />
                    <Route path={R_ANEELANUAL} exact component={AneelAnual} />
                    <Route
                        path={R_ANEELANUALAGREG}
                        exact
                        component={AneelAnualAgregado}
                    />
                    <Route
                        path={R_ANEELQUALIDADE}
                        exact
                        component={AneelQualidade}
                    />
                    <Route
                        path={R_ANEELATENDIMENTO}
                        exact
                        component={AneelAtendimento}
                    />
                    <Route path={R_CCVMENSAL} exact component={CCV} />
                    <Route path={R_BSCIAB} exact component={BSCIAB} />
                    <Route path={R_BSCICO} exact component={BSCICO} />
                    <Route path={R_BSCINS} exact component={BSCINS} />
                    <Route path={R_BSCTMA} exact component={BSCTMA} />
                    <Route
                        path={ADM_RESET_PASS}
                        exact
                        component={() => (
                            <RedefinirSenha
                                idUser={this.props.loggedUser.UID}
                            ></RedefinirSenha>
                        )}
                    />
                    <Route
                        path={ADM_TIPOUSUARIO}
                        exact
                        component={() => (
                            <AlterarTipoUsuario
                                idUser={this.props.loggedUser.UID}
                            ></AlterarTipoUsuario>
                        )}
                    />
                    <Route path={R_CCVANUAL} exact component={ccvanual} />
                    <Route
                        path={R_CUSTOTELEFONIA}
                        exact
                        component={custotelefonia}
                    />
                    <Route path={R_CUSTODEMAIS} exact component={CustoDemais} />
                    <Route
                        path={R_CUSTOCONSOLIDADO}
                        exact
                        component={Consolidado}
                    />
                    <Route path={R_BSCURA} exact component={BSCUra} />
                    <Route
                        path={R_BSCMINUTAGEM}
                        exact
                        component={BSCMinutagem}
                    />
                    <Route
                        path={R_BSCATENDIDAS}
                        exact
                        component={BSCAtendidas}
                    />
                    <Route
                        path={R_BSCRECEBIDAS}
                        exact
                        component={BSCRecebidas}
                    />
                    <Route path={R_BSCRETURA} exact component={BSCRETURA} />
                    <Route path={R_BSCRETNUM} exact component={BSCRETNUM} />
                    <Route
                        path={R_ORCAMENTOMENSAL}
                        exact
                        component={OrcamentoMensal}
                    />
                    <Route
                        path={R_ORCAMENTOANUAL}
                        exact
                        component={OrcamentoAnual}
                    />
                    <Route
                        path={R_ANEELDETALHE}
                        exact
                        component={AneelDetalheDiario}
                    />
                    <Route component={NoWhere} />
                </Switch>
            </Router>
        );
    }
}

export default LayoutContent;
