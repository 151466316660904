import React, { Component, useState } from "react";
import PropTypes from "prop-types";

import LayoutSkeleton from "../../components/Layout/Skeleton";

class Layout extends Component {
    static propTypes = {
        // location: PropTypes.object.isRequired
    };
    render() {
        return <LayoutSkeleton />;
    }
    componentDidMount() {}
}

export default Layout;
